import React, {useContext} from 'react';
import { ContactsContainer, Contacts } from './contacts.component.styles';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import { SeparatorComponent } from '../separator/broken-line.separator.component';

export const ContactsComponent = () => {
    const {currentScreen} = useContext(ResponsiveScreenContext);
    
    return(
        <ContactsContainer className={currentScreen}>
            <SeparatorComponent text={<h1>Contacts</h1>} />
            <Contacts className={currentScreen === 'mobile' || currentScreen === 'smobile' ? 'column-ourstory' : 'row-ourstory'}>
                <div className='contact-number'>
                    +63 917 770 7796 Philippines
                </div>
                <div className='contact-number'>
                    +46 70 260 37 67 Sweden
                </div>
            </Contacts>
        </ContactsContainer>
    )
}