import React, { useContext, useState } from 'react';
import { FormContext } from '../../contexts/form.context';
import { IsContext } from '../../contexts/is.context';
import { LabelInputComponent } from '../label-and-input/label-and-input.component';
import { FormContainer,
  ButtonContainer, 
  BookingFormContainer, 
  PickupServiceContainer,
  FaBusinessTimeContainer
 } from './tour.booking.form.styles';
import { Button, BUTTON_TYPE_CLASSES } from '../button/button.component';
import { SeparatorComponent } from '../separator/broken-line.separator.component';
import { AcceptedPaymentComponent } from '../accepted-payment/accepted-payment.component';
import { SwitchToggleComponent } from '../toggle/switch.toggle.component';
import { TimePickerDropdownComponent } from '../dropdown/time.picker.dropdown.component';


export const BookingFormComponent = ({selectedPackage, selectedTour}) => {
  const [isPickupService, setIsPickupService] = useState(false);
  const [isArrival, setIsArrival] = useState(false);
  const [isDeparture, setIsDeparture] = useState(false);
  const [isAdditionalInfo, setIsAdditionalInfo] = useState(false);
  const [value, setValue] = useState('10:00');

  const {
    formFields,
    setFormFields,
    setOrderDetails,
  } = useContext(FormContext);

  const {isCheckoutForm,
    setIsCheckoutForm,
    isSelectedHour,
    isSelectedMinute,
    isAmPm
  } = useContext(IsContext);

  const { fullName, 
    email, 
    contactNumber, 
    numberOfGuest, 
    desiredDate, 
    pickupLocation,
    additionalInformation,
    dateOfArrival,
    timeOfArrival,
    dateOfDeparture
   } = formFields;
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(value)

    setFormFields({...formFields, [name]: value});

  };

  
  const handleInput = (event) => {
    let { value } = event.target;
  
    if (value < 0) {
      value = Math.abs(value);
    }

    const price = selectedTour.prices.find((price) => price.pax === parseInt(value, 10));

    if(price) {
      const total = price.pax * price.ratePerPerson;
  
      setOrderDetails((prevFields) => ({
          ...prevFields,
          packages:
            {
              packageName: selectedPackage.packageName,
              tourName: selectedTour.tourName,
              numberOfGuests: price.pax,
              ratePerPerson: price.ratePerPerson,
              total: total
            },
          totalAmount: total
        }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    const updatedFormFields = { ...formFields };
    Object.keys(updatedFormFields).forEach((fieldName) => {
      if (updatedFormFields[fieldName] === '') {
        updatedFormFields[fieldName] = 'Not Specified';
      }
    });

    console.log(updatedFormFields)
    setFormFields(updatedFormFields);
    setIsCheckoutForm(!isCheckoutForm);
  };
  
  
  return (
    <BookingFormContainer>
        <FormContainer onSubmit={handleSubmit}>
         <div className='booking-form-header'>
          <SeparatorComponent text={
              <h2>
                  Booking Form
              </h2>
            }/>
         </div>
          <LabelInputComponent 
            label='Full Name'
            type='text'
            name='fullName'
            value={fullName}
            onChange={handleChange}
            required
          />
          <LabelInputComponent 
            label='Email'
            type='email'
            name='email'
            value={email}
            onChange={handleChange}
            required
          />
          <LabelInputComponent 
            label='Contact Number'
            type='number'
            min='0'
            name='contactNumber'
            value={contactNumber}
            onChange={handleChange}
            onInput={handleInput}
          />
          <LabelInputComponent 
            label='Number of Guest'
            name='numberOfGuest'
            type='number'
            min="2"
            max='12'
            value={numberOfGuest}
            onChange={handleChange}
            onInput={handleInput}
            required
          />
          <LabelInputComponent
            label={'Desired Date (month/day/year)'}
            name='desiredDate'
            type='date'
            value={desiredDate}
            onChange={handleChange}
            placeholder={'Desired Date'}
            required
          />
          <LabelInputComponent
            textArea
            label='Pickup Location'
            name='pickupLocation'
            value={pickupLocation}
            onChange={handleChange}
            required
          />

          <SwitchToggleComponent
              label={'Additional Info'}
              value={additionalInformation}
              onChange={() => setIsAdditionalInfo(!isAdditionalInfo)}
            />
            { isAdditionalInfo &&
                <LabelInputComponent
                  textArea
                  label='Additional Information (Special Request)'
                  name='additionalInformation'
                  value={additionalInformation}
                  onChange={handleChange}
                />
            }
          <SwitchToggleComponent
            label={'Pickup Service'}
            value={'pickupService'}
            onChange={() => [setIsPickupService(!isPickupService), setIsArrival(false), setIsDeparture(false)]}
          />
          { isPickupService &&
          <PickupServiceContainer>
            <SwitchToggleComponent
              label={'Arrival'}
              value={'arrival'}
              onChange={() => setIsArrival(!isArrival)}
            />
            { isArrival &&
              <div className='arrival'>
                <LabelInputComponent
                  label={'Date of Arrival'}
                  name='dateOfArrival'
                  type='date'
                  value={dateOfArrival}
                  onChange={handleChange}
                  placeholder='Date of Arrival'
                  required
                />
              </div>
            }
            <SwitchToggleComponent
              label={'Departure'}
              value={'departure'}
              onChange={() => setIsDeparture(!isDeparture)}
            />
            { isDeparture &&
              <div className='departure'>
                <LabelInputComponent
                  label={'Date of Departure'}
                  name='dateOfDeparture'
                  type='date'
                  value={dateOfDeparture}
                  onChange={handleChange}
                  placeholder='Date of Departure'
                  required
                />
              </div>
            }
          </PickupServiceContainer>
          }
          <ButtonContainer>
            <Button type='submit' buttonType={BUTTON_TYPE_CLASSES.inverted}>
              Proceed to Checkout
            </Button>
          </ButtonContainer>
        </FormContainer>
        <div className='accepted-payment'>
          <SeparatorComponent text={
              <h3>
                  Payment Methods
              </h3> }
          />
          <AcceptedPaymentComponent />
        </div>
    </BookingFormContainer>
  )
}

/*
              <div className='arrival'>
                <LabelInputComponent
                  label={'Date of Arrival'}
                  name='dateOfArrival'
                  type='date'
                  value={dateOfArrival}
                  onChange={handleChange}
                  placeholder={'Date of Arrival'}
                  required
                />
                <LabelInputComponent
                  label={'Time of Arrival'}
                  name='timeOfArrival'
                  type='time'
                  value={timeOfArrival}
                  onChange={handleChange}
                  placeholder={'Time of Arrival'}
                  required
                />
              </div>
*/