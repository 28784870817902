import React from 'react';
import { ChakraProvider } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom';
import { RouteNameProvider } from './contexts/route-name.context';
import { createRoot } from 'react-dom/client';
import { ResponsiveScreenProvider } from './contexts/screen.context';
import { PackageProvider } from './contexts/packages.data.context';
import { IsProvider } from './contexts/is.context';
import { FormProvider } from './contexts/form.context';
import { Elements } from "@stripe/react-stripe-js";

import App from './App';
import { stripePromise } from './utilities/stripe.util';

import './global.styles.js';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <BrowserRouter>
        <RouteNameProvider>
          <ResponsiveScreenProvider>
              <PackageProvider>
                    <IsProvider>
                      <FormProvider>
                        <Elements stripe={stripePromise}>
                          <App />
                        </Elements>
                      </FormProvider>
                    </IsProvider>
              </PackageProvider>
          </ResponsiveScreenProvider>
        </RouteNameProvider>
      </BrowserRouter>
    </ChakraProvider>
</React.StrictMode>
);