import React, { useContext } from 'react';
import { IsContext } from '../../contexts/is.context';
import { StyledLI } from './list.styles'
import { Link } from 'react-router-dom';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { PackagesAndTourDropdownComponent } from '../dropdown/packages.dropdown.component';

export const PackagesListComponent = ({ linkTo, text, onClick }) => {
    const { setIsMenuOpen } = useContext(IsContext);
    const { isHover, setIsHover } = useContext(IsContext);

    const handleOnMouseOver = () => {
      setIsHover(true);
    };
  
    const handleMouseLeave = () => {
      setIsHover(false);
    };

    const handleOnClick = () => {
      setIsHover(!isHover);
    }

    const toggleIcons = () => {
      setIsMenuOpen(false);
      setIsHover(false);
      window.scrollTo(0, 0);
      
      if (onClick) {
        onClick();
      }

    };

    return (
    
    <div
      onMouseOverCapture={handleOnMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={handleOnClick}
    >
      <StyledLI>
        <Link className='packages-and-tour link' to={linkTo} onClick={toggleIcons}>
          {text}
          <>
          {isHover ? (
            <FiChevronUp className='icon' />
          ):(
            <FiChevronDown className='icon' />
          )}
          </>
        </Link>
        
      </StyledLI>
      {isHover && 
         <PackagesAndTourDropdownComponent />
      }
    </div>
    );
};
    

