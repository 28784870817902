import styled from 'styled-components';
import { text } from '../../utilities/sizes.util';

export const FooterLogoContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   gap: 10px;

   img {
    width: 90px;
    height: 90px;
    border-radius: 100%;
  }

  strong {
    color: black;
    font-size: ${text.medium}
  }
`;


export const HeaderLogoContainer = styled.div`
  display: flex;
  
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;

  img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }
  
`;

export const LogoText = styled.div`
  margin: 0 4px;
  font-size: ${text.logoText};

`;

