import React from 'react';
import { useContext } from 'react';
import { IsContext } from '../../../contexts/is.context';

import { HamburgereMenuContainer, HamburgerIcon } from './icon.hamburger.styles';

export const HamburgerIconComponent = () => {
  const { isMenuOpen, setIsMenuOpen } = useContext(IsContext);

  const toggleIsMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <HamburgereMenuContainer onClick={toggleIsMenuOpen}>
        <HamburgerIcon />
    </HamburgereMenuContainer>
  );
};