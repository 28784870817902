import { loadStripe } from '@stripe/stripe-js';


export const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

let stripe
export const getStripe = () => {
  if(!stripePromise) {
    stripePromise = stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
}

return stripe;
}

