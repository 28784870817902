import styled from 'styled-components';


export const ContactsContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
`;

export const Contacts = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*border-radius: 8px;
    border: 2px solid #DDD;
    padding: 20px 60px;
    max-width: 824px;
    width: 100%;*/


    .contact-number {
        text-align: center;
    }
`;