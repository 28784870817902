const deviceSizes = {
  smobile: '425px',
  mobile: '625px',
  tablet: '1025px',
  laptop: '2000px',
  desktop: '3000px',
}

export const device = {
  smobile: `(max-width: ${deviceSizes.smobile})`,
  mobile: `(max-width: ${deviceSizes.mobile})`,
  tablet: `(max-width: ${deviceSizes.tablet})`,
  laptop: `(max-width: ${deviceSizes.laptop})`,
  desktop: `(max-width: ${deviceSizes.desktop})`,
};

export const text = {
  hone: 'calc(18px + 1vmax)',
  htwo: 'calc(14px + 1vmax)',
  hthree: 'calc(10px + 1vmax)',
  hfour: 'calc(8px + 1vmax)',
  hfive: 'calc(6px + 1vmax)',
  hsix: 'calc(4px + 1vmax)',
  list: 'calc(18px + 1vmin)',
  button: 'calc(12px + 1vmin)',
  paragraph: 'calc(12px + 1vmin)',
  logoText: 'calc(24px + 1vmin)',
  xxsmall: '8px',
  xsmall: '12px',
  small: '16px',
  medium: '20px',
  large: '24px',
  xlarge: '28px',
  xxlarge: '32px',
};

export const icon = {
  xsmall: 'calc(6 + 1vmin)',
  small: 'calc(8px + 2vmin)',
  medium: 'calc(10px + 3vmin)',
  large: 'calc(12px + 4vmin)',
  xlarge: 'calc(14px + 5vmin)',
  xxlarge: 'calc(16px + 6vmin)',
}