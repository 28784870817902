import React, {useContext} from 'react';
import { PersonalContainer, Image} from './personals.component.styles';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import { CardComponent } from '../card/card.component';
import TouristDriver from '../../assets/driver.png';
import ContactPerson  from '../../assets/martites.png'
import { SeparatorComponent } from '../separator/broken-line.separator.component';

export const PersonalComponent = () => {
    const {currentScreen} = useContext(ResponsiveScreenContext);
    
    return(
        <PersonalContainer className={currentScreen}>
            <SeparatorComponent text={<h1>Personals</h1>} />
            <Image className={currentScreen === 'mobile' || currentScreen === 'smobile' ? 'column-personals' : 'row-personals'}>
                <div>
                    <CardComponent
                        key={'Contact Person'}
                        image={ContactPerson}
                        imageName={'Marites Tihap'}
                        details={'Contact Person'}
                    />
                </div>
                <div>
                    <CardComponent
                        key={'Tourist Driver'}
                        image={TouristDriver}
                        imageName={'Rick Barry Pacot'}
                        details={'Tourist Driver'}
                    />
                </div>
            </Image>
        </PersonalContainer>
    )
}