import React, { useContext } from 'react';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import { IsContext } from '../../contexts/is.context';
import { StyledLI } from './list.styles';
import { Link } from 'react-router-dom';

export const ListComponent = ({ linkTo, text, onClick }) => {
    const { currentScreen } = (ResponsiveScreenContext);
    const { setIsMenuOpen } = useContext(IsContext);

    const toggleIcons = () => {
      setIsMenuOpen(false);
  
      if (onClick) {
        onClick();
      }
    };

    return (
      <StyledLI className={currentScreen} onClick={toggleIcons}>
        <Link className='li link' to={linkTo}>
          {text}
        </Link>
      </StyledLI>
    );
};
    

