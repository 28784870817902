import React, {useContext } from 'react';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import { SeparatorComponentContainer } from "./broken-line.separator.styles";

export const SeparatorWithTextComponent = ({ text }) => {
  const {currentScreen} = useContext(ResponsiveScreenContext)
  let dashCount = 500;  // Adjust the multiplier as needed
    return (
        <SeparatorComponentContainer className={currentScreen}>
            <div className="line-with-text" style={{ maxWidth: `${dashCount}px`, width: '100%' }} />
              {text && 
              <div className="text">{text}</div> }
            <div className="line-with-text" style={{ maxWidth: `${dashCount}px`, width: '100%' }} />
        </SeparatorComponentContainer>
    );
  };

export const SeparatorComponent = ({ text }) => {
  const {currentScreen} = useContext(ResponsiveScreenContext)
    const dashCount = currentScreen === 'smobile' ? 40 : currentScreen === 'mobile' ? 60 : 100; // Adjust the multiplier as needed
    
    return (
      <SeparatorComponentContainer className={currentScreen}>
        <div>
          <div className="left1" style={{ width: `${dashCount}px` }} />
          <div className="right1" style={{ width: `${dashCount}px` }} />
        </div>
            {text && 
                  <div className="text">{text}</div> }
          <div>
            <div className="left2" style={{ width: `${dashCount}px` }} />
            <div className="right2" style={{ width: `${dashCount}px` }} />
          </div>
      </SeparatorComponentContainer>
    );
  };
  