import styled from 'styled-components';
import { icon} from '../../utilities/sizes.util';

export const PackagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  &.tablet {
    .packages-and-tour-list-component-container {
      padding-top: 60px;
    }
    .grid-container {
      .item {
        flex: 0 0 calc(33% - 20px);
      }
    }
  }

  &.mobile,
  &.smobile {
    .grid-container {
      padding-top: 150px;
      .item {
        flex: 0 0 calc(100% - 10px);
        padding: 0 10px 15px 10px;
        margin: 0;
      }
    }
  }

`;
export const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    .packages-and-tour {
      padding: 110px 0 20px 0;
    }
    
`;


export const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0 15px 0;
`;

export const Item = styled.div`
flex: 0 0 calc(25% - 5px);
padding: 10px;
margin: 0;

`;
export const PackagesListComponentContainer = styled.div`
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 996;
    padding-top: 60px;
    background-color: white;
    border-bottom: 2px solid rgba(1,1,1,0.20);
    width: 100%;

    .packages-and-tour {
      padding: 15px 0;

      .icon {
        font-size: ${icon.large};
      }
    }

    h2 {
      padding: 0;
      margin: 0;
    }

`;


