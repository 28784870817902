import React, { createContext, useState, useEffect }  from 'react';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import { device } from '../utilities/sizes.util';

// Create the context with default values
export const ResponsiveScreenContext = createContext({
  currentScreen: 'desktop',
  setCurrentScreen: () => {},
});

// Create the provider component
export const ResponsiveScreenProvider = ({ children }) => {
  // Define media queries
  const isDesktopScreen = useMediaQuery({ query: device.desktop });
  const isLaptopScreen = useMediaQuery({ query: device.laptop});
  const isTabletScreen = useMediaQuery({ query: device.tablet });
  const isMobileScreen = useMediaQuery({ query: device.mobile });
  const isSMobileScreen = useMediaQuery({ query: device.smobile });
  
  

  // Define state variables
  const [currentScreen, setCurrentScreen] = useState('desktop');

  // Define a useEffect to update the currentScreen state based on media queries
  useEffect(() => {
    if (isDesktopScreen) {
      setCurrentScreen('desktop');
    } if (isLaptopScreen) {
      setCurrentScreen('laptop');
    } if (isTabletScreen) {
      setCurrentScreen('tablet');
    } if (isMobileScreen) {
      setCurrentScreen('mobile');
    } if (isSMobileScreen) {
      setCurrentScreen('smobile');
    }
  }, [isDesktopScreen, isLaptopScreen, isTabletScreen, isMobileScreen, isSMobileScreen]);

  const value = {
    currentScreen,
    setCurrentScreen,
    };

  // Return the context provider with the currentScreen state and setCurrentScreen function
  return (
    <ResponsiveScreenContext.Provider value={value}>
      {children}
    </ResponsiveScreenContext.Provider>
  );
};
