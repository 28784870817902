import React, { useContext } from 'react';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import { HeaderLogoContainer, LogoText } from './logo.styles';
import LogoImage from '../../assets/logo152.png'

export const HeaderLogoComponent = () => {
  const {currentScreen} = useContext(ResponsiveScreenContext);

  return (
      <HeaderLogoContainer className={currentScreen}>
        { currentScreen === 'tablet' || currentScreen === 'mobile' || currentScreen === 'smobile' ? (
          <img src={LogoImage} alt='Logo'/>
        ):(
          <>
            <img src={LogoImage} alt='Logo'/>
            <LogoText><strong>Booking Gateway</strong></LogoText>
          </>
        )}
      </HeaderLogoContainer>
  );
}