import React, {useContext} from "react";
import { TourListNavContainer, ArrowLeft } from "./nav.component.styles";
import { IsContext } from "../../contexts/is.context";
import { ListComponent } from "../list/list.component";
import { Link } from "react-router-dom";

export const TourListNavComponent = ({listName}) => {
    const { isDetails,
        setIsDetails,
        isBookingForm,
        setIsBookingForm,
        isCheckoutForm,
        setIsCheckoutForm,
    } = useContext(IsContext)

    const handleTableClick = () => {
        setIsDetails(!isDetails);
        setIsBookingForm(false);
    }

    const handleFormClick = () => {
        setIsBookingForm(!isBookingForm);
        setIsDetails(false);
    }

    const handleCheckoutFormClick = () => {
        setIsCheckoutForm(false);
        setIsDetails(false);
        setIsBookingForm(false)
    }

    return (
        <TourListNavContainer>
            <div className='list'>
                <Link className='arrow' to={'/packages'}>
                    <ArrowLeft />
                </Link>
                <span>|</span>
                <div className='list-name'>
                    <ListComponent text={listName}/>
                </div>
            </div>
            <div className='list-group'>
                {isDetails ? (
                    <ListComponent text={'Rates & Prices'} onClick={handleTableClick}/>
                ):(
                    <ListComponent text={'Tour Details'} onClick={handleTableClick}/>
                )}
                <span>|</span>
                {isBookingForm ? (
                    <>
                    {isCheckoutForm ? (
                        <ListComponent text={'Cancel'} onClick={handleCheckoutFormClick}/>
                    ):(
                        <ListComponent text={'Go Back'} onClick={handleFormClick}/>
                    )}
                    </>
                ):(
                    <ListComponent text={'Book Now'} onClick={handleFormClick}/>
                )}
                
            </div>
        </TourListNavContainer>
    );
}
