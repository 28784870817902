import React, { useState, useContext, useEffect } from 'react';
import { TourContainer,
  TourBigScreenContainer, 
  TourSmallScreenContainer,
  TourListNavComponentContainer
} from "./tour.styles";
import { useNavigate } from "react-router-dom";
import { PackageContext } from '../../contexts/packages.data.context';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import { RoutNameContext } from '../../contexts/route-name.context';
import { IsContext } from '../../contexts/is.context';
import { CarouselSliderComponent } from './carousel-slider/carousel-slider.tour';
import { TableComponent } from '../../components/table/tour.table.component';
import { BookingFormComponent } from '../../components/form/tour.booking.form.compont';
import { CheckoutFormComponent } from '../../components/form/tour.checkout.form.component';
import { SeparatorComponent } from '../../components/separator/broken-line.separator.component';
import { FooterComponent } from '../../components/footer/footer.component';
import { TourListNavComponent } from '../../components/nav/tour.list.nav.component';

export const Tour = () => {

    const { pkgs } = useContext(PackageContext);
    const { currentScreen } = useContext(ResponsiveScreenContext);
    const { routeName, setRouteName } = useContext(RoutNameContext);
    const {
      isBookingForm,
      isCheckoutForm,
      isDetails
    } = useContext(IsContext);
    const [selectedPackage, setSelectedPackage] = useState(pkgs[0]);
    const [selectedTour, setSelectedTour] = useState(selectedPackage.tours[0]);


    useEffect(() => {
      // Initialize a variable to store the found package
      let foundPackage = null;
    
      // Loop through each package to find the one with the matching tour
      for (const packageItem of pkgs) {
        const foundTour = packageItem.tours.find((tour) => tour.tourName === routeName);
    
        if (foundTour) {
          // If the tour is found within the package, set foundPackage and break the loop
          foundPackage = packageItem;
          setSelectedTour(foundTour);
          setSelectedPackage(foundPackage);
          break;
        }
      }
  
    }, [routeName]);
    
 
    return (
        <TourContainer className={currentScreen}>
          {(currentScreen === 'mobile' || currentScreen === 'smobile') ? (
          <TourSmallScreenContainer>
                <TourListNavComponent listName={selectedTour.tourName}/>
                { !isBookingForm &&
                <section className='card-and-tourname-section'>
                  {isDetails ? (
                    <section className='details-and-title'>
                        <SeparatorComponent text={<h3>Tour Details</h3>}/>
                        <article className='details'>
                            <p>
                              {selectedPackage.details}
                            </p>
                        </article>
                    </section>
                  ):(
                    <section className='card'>
                      <CarouselSliderComponent data={selectedPackage}/>
                      <SeparatorComponent text={<h3>Rates and Prices</h3>}/>
                    </section>
                  )}
                </section>
              }
            <section className='table-and-booking-form-section'>
                <section className='table-and-booking-form'>
                  {isBookingForm ? (
                    <aside className='booking-form'>
                      { isCheckoutForm ? (
                        <CheckoutFormComponent selectedPackage={selectedPackage}/>
                      ) : (
                        <BookingFormComponent selectedPackage={selectedPackage} selectedTour={selectedTour}/>
                      )}
                    </aside>
                  ):(
                    <section className='table'>
                      <TableComponent selectedPackage={selectedPackage}  selectedTour={selectedTour} setSelectedTour={selectedTour}/>
                    </section>
                  )}
                </section>  
            </section>
            <FooterComponent />
          </TourSmallScreenContainer>
          ):(
        <TourBigScreenContainer>
        <section className='card-and-tourname-section'>
            <section className='card'>
              <CarouselSliderComponent data={selectedPackage}/>
            </section>
            <section className='tour-name-details'>
              <div className='tour-name'>
                <SeparatorComponent text={<h3>{selectedTour.tourName}</h3>}/>
              </div>
                <article className='details'>
                  <p>
                    {selectedPackage.details}
                  </p>
                </article>
            </section>
        </section>
        <section className='table-and-booking-form-section'>
            <section className='table-and-booking-form'>
                <section className='table'>
                  <TableComponent selectedPackage={selectedPackage}  selectedTour={selectedTour} setSelectedTour={selectedTour}/>
                </section>
                <aside className='booking-form'>
                { isCheckoutForm ? (
                  <CheckoutFormComponent selectedPackage={selectedPackage}/>
                ) : (
                  <BookingFormComponent selectedPackage={selectedPackage} selectedTour={selectedTour}/>
                )}
                </aside>
            </section>  
        </section>
        <FooterComponent />
        </TourBigScreenContainer>
          )}
    </TourContainer>
    )
}

/**
     const tourSelectOptions = selectedPackage.tours.map((tour) => ({
      value: tour.tourName,
      label: tour.tourName,
    }));


    const handleSeletedTour = (tourName) => {
      setRouteName(tourName)
      const foundTour = selectedPackage.tours.find((tourItem) =>
        tourItem.tourName === routeName
      );
    
      if (foundTour) {
        setSelectedTour(foundTour);
      }
    };
 */