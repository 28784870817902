import React, {useContext} from "react";
import { ResponsiveScreenContext } from "../../contexts/screen.context";
import { PackageContext } from "../../contexts/packages.data.context";
import { PackagesContainer, Item, Grid, GridContainer, PackagesListComponentContainer } from "./packages.styles";
import { CardComponent } from "../../components/card/card.component";
import { PackagesListComponent } from "../../components/list/packages.list.component";
import { SeparatorComponent } from "../../components/separator/broken-line.separator.component";
import { FooterComponent } from "../../components/footer/footer.component";

export const Packages = () => {
    const {currentScreen} = useContext(ResponsiveScreenContext);
    const { tours } = useContext(PackageContext);

  return (
    <PackagesContainer className={currentScreen}>
      {(currentScreen === 'mobile' || currentScreen === 'smobile') &&
          <PackagesListComponentContainer className='packages-and-tour-list-component-container'>
            <PackagesListComponent text={<h2>Packages & Tours</h2>}/>
          </PackagesListComponentContainer>
      }
      <GridContainer className='grid-container'>
      {(currentScreen === 'desktop' || currentScreen === 'laptop' || currentScreen === 'tablet') && 
        <div className='packages-and-tour'>
          <SeparatorComponent text={<h3>Packages & Tours</h3>}/>
        </div>
      }
        <Grid className='grid'>
          {tours.map((tour, index) => (
                <Item className='item' key={index}>
                <CardComponent
                  key={index}
                  header={tour.tourName}
                  image={tour.image.source}
                  imageName={tour.image.name}
                  details={`From ${tour.prices[10].ratePerPerson} PHP rate/person for a group of 12`}
                  tourName={tour.tourName}
                />
                </Item>
              ))}
        </Grid>
      </GridContainer>
      <FooterComponent />
    </PackagesContainer>
  );
};