import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import { DesktopFooterContainer, 
    MobileFooterContainer, 
    FooterContainer,
 } from './footer.styles';
import { FooterLogoComponent } from '../logo/footer.logo.component';
import { AcceptedPaymentComponent } from '../accepted-payment/accepted-payment.component';
import { SeparatorComponent } from '../separator/broken-line.separator.component';


export const FooterComponent = () => {
    const {currentScreen} = useContext(ResponsiveScreenContext);
    

    const handleClick = () => {
        window.scrollTo(0, 0);
    }

    return (
        <>
        {currentScreen === 'tablet' || currentScreen === 'mobile' || currentScreen === 'smobile' ? (
        <FooterContainer>
            <MobileFooterContainer className={` ${currentScreen} aboutus`}>
                <div className='accepted-payments'>
                    <h2>Payment Methods</h2>
                    <AcceptedPaymentComponent />   
                </div>

                <div className='info'>
                        <ul className='about-us'>
                            <h3>About Us</h3>
                            <li onClick={handleClick}>
                                <Link to={'/aboutus'}>
                                    Our Story
                                </Link>
                            </li>
                        </ul>
                        
                        <ul className='social-media'>
                            <h3>Social Media</h3>
                            <li>
                                <Link to={'https://www.facebook.com/profile.php?id=100067894652830'}>
                                    Facebook
                                </Link>
                            </li>
                        </ul>
        
                        <ul className='customer-service'>
                        <h3>Contacts</h3>
                            <li>
                                <Link >
                                    +63 917 770 7796 Philippines
                                </Link>
                            </li>
                            <li>
                                <Link >
                                    +46 70 260 37 67 Sweden
                                </Link>
                            </li>
                        </ul>
                </div>
                <div className='logo'>
                    <FooterLogoComponent />
                </div>
            </MobileFooterContainer>
            <div class='copyright-text-mobile'>
                <div>
                    &copy; 2023 April Rose Veloro.
                </div>
                <div>
                    All Rights Reserved.
                </div>
                
            </div>
        </FooterContainer>

            ):(
            <FooterContainer>
                <DesktopFooterContainer className={`${currentScreen}-aboutus`}>
                    <div className='logo'>
                        <FooterLogoComponent />
                    </div>
                    <div className='info'>
                        <div className='about-and-social'>
                            <ul className='about-us'>
                                <h3>About Us</h3>
                                <li onClick={handleClick}>
                                    <Link to={'/aboutus'}>
                                        Our Story
                                    </Link>
                                </li>
                            </ul>
                            
                            <ul className='social-media'>
                                <h3>Social Media</h3>
                                <li>
                                    <Link to={'https://www.facebook.com/profile.php?id=100067894652830'}>
                                        Facebook
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className='customer-service'>
                            <ul className='contacts'>
                                <h3>Contacts</h3>
                                    <li>
                                        <Link >
                                            +63 917 770 7796 Philippines
                                        </Link>
                                    </li>
                                    <li>
                                        <Link >
                                            +46 70 260 37 67 Sweden
                                        </Link>
                                    </li>
                                </ul>
                        </div>
                    </div>
                    <div className='accepted-payments'>
                        <h2>Payment Methods</h2>
                        <AcceptedPaymentComponent />   
                    </div>
                </DesktopFooterContainer>
                <div class='copyright-text-desktop'>
                    &copy; 2023 April Rose Veloro.  All Rights Reserved.
                </div>
            </FooterContainer>
        )}
        </>
    )
}