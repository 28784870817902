import React, { createContext, useState } from 'react';
import { packages, tourData} from '../utilities/packages.datas.util'

export const PackageContext = createContext({
    pkgs: [packages],
    setPks: () => {},
    tours: [tourData],
    setTours: () => {}
  });

export const PackageProvider = ({ children }) => {
    const [pkgs, setPks] = useState(packages);
    const [tours, setTours] = useState(tourData);

    const value = {
        pkgs,
        setPks,
        tours,
        setTours
    };

    return (
        <PackageContext.Provider value={value}>
            {children}
        </PackageContext.Provider>
    );
};

