import React, { createContext, useContext, useState } from 'react';

const defaultFormFields = {
  fullName: '',
  email: '',
  contactNumber: '',
  numberOfGuest: '',
  desiredDate: '',
  pickupLocation: '',
  additionalInformation: '',
  dateOfArrival: '',
  dateOfDeparture: '',
  timeOfArrival: '',
  timeOfDeparture: ''
};

const addOnFields = {
  addons: [],
}

const defaultOrderDetails = {
  packages: {
    packageName: '',
    tourName: '',
    numberOfGuests: '',
    ratePerPerson: '',
    total: '',
  },
  addons: [],
  totalAmount: ''
}

export const FormContext = createContext({
    formFields: defaultFormFields,
    setFormFields: () => {},
    orderDetails: defaultOrderDetails,
    setOrderDetails: () => {},
});


export const FormProvider = ({ children }) => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [orderDetails, setOrderDetails] = useState(defaultOrderDetails)

  const value = {
    formFields,
    setFormFields,
    orderDetails,
    setOrderDetails,
    };

  return (
    <FormContext.Provider value={value}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => useContext(FormContext);
