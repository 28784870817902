import styled from 'styled-components';

export const BookingFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;



  h3 {
    text-align: center;
  }

  .accepted-payment {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 60px 0 30px 0;
  }

  .date-and-time {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
  }

  .date-of-arrival-label {
    padding: 0 0 -20px 10px
  }
`;


export const FormContainer = styled.form`
  .booking-form-header{
    display: block;
    align-items: center;
    
    h2 {
      text-align: center;
    }

    span {
      text-decoration: underline;
      color: deepskyblue
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0 0 0;

  button {
    width: 100%;
    padding: 40px 0;
  }
`;

export const PickupServiceContainer = styled.div`
  diplay: flex;
  flex-direction: column;
`;