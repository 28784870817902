import React, {useContext} from 'react';
import { ContactsContainer } from './contacts.route.styles';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import { FooterComponent } from '../../components/footer/footer.component';
import { SeparatorComponent } from '../../components/separator/broken-line.separator.component';
import { ContactsComponent } from '../../components/contacts/constacts.component';
import { PersonalComponent } from '../../components/personals/personals.component';


export const Contacts = () => {
    const {currentScreen} = useContext(ResponsiveScreenContext);
    
    return (
        <ContactsContainer className={currentScreen}>
            <ContactsComponent />
            <PersonalComponent />
            <FooterComponent /> 
        </ContactsContainer>
    )
}