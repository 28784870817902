import React from 'react';
import {
    signInWithGooglePopup,
    //createUserDocumentFromAuth,
  } from '../../utilities/firebase.util';
  import { Button, BUTTON_TYPE_CLASSES } from '../../components/button/button.component';
  
  export const SignIn = () => {
    const logGoogleUser = async () => {
      signInWithGooglePopup();
      //const userDocRef = await createUserDocumentFromAuth(user);
    };
  
    return (
      <div style={{padding: '100px', display: 'flex'}}>
        <h1>Sign In Page</h1>
        <Button buttonType={BUTTON_TYPE_CLASSES.signInWithGooglePopup} onClick={logGoogleUser}>Sign in with Google Popup</Button>
      </div>
    );
  };
  
  /**
   import React from 'react';
import {
    signInWithGooglePopup,
    createUserDocumentFromAuth,
  } from '../../utilities/firebase.utilities';
  import { Button, BUTTON_TYPE_CLASSES } from '../../components/button/button.component';
  
  export const SignIn = () => {
    const logGoogleUser = async () => {
      const { user } = await signInWithGooglePopup();
      const userDocRef = await createUserDocumentFromAuth(user);
    };
  
    return (
      <div style={{padding: '100px', display: 'flex'}}>
        <h1>Sign In Page</h1>
        <Button buttonType={BUTTON_TYPE_CLASSES.signInWithGooglePopup} onClick={() => logGoogleUser}>Sign in with Google Popup</Button>
      </div>
    );
  };
   */