import styled from 'styled-components';
import { text } from '../../utilities/sizes.util';

export const Form = styled.form`

    .booking-form-header{
      display: block;
      align-items: center;
      
      h2 {
        text-align: center;
      }

      h3 {
        text-align: center;
        colir: blue;
      }
 
    }

    .addonTitle {
      font-weight: bold;
    }

    .total-amount {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-weight: bold;
      padding: 15px 0;
    }


`;

export const ProvidedInformationContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const PackageAndTourContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const AddonContainer = styled.div`
    padding: 5px 0 0 0;
 
`;

export const FormChildrenContainer = styled.div`
    display: flex;
    flex-direction: column;

  p {
    text-align: left !important;
    margin: 0;
    padding: 0;
  }
  span {
    font-weight: bold;
  }

  .cost-to-pay {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;

    .amount {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .subtotal {
      font-weight: bold;
    }
  }

  &.orderInfoContainer {

    .order-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
  
      .label-addon {
        padding-left: 10px;
        font-size: ${text.small}
      }
  
      .minus {
        display: flex;
        align-items: center;
        height: 15px;
        padding: 0 4px;
        font-size: ${text.small}
        
      }
  
      .plus {
        display: flex;
        align-items: center;
        padding: 0 4px;
        height: 15px;
        font-size: ${text.xsmall}
      }
    }

    .addon-info-price {
      text-align: right !important;
      margin: 30px 0:
    }
  }


  input {
    border: none;
    outline: none; /* This removes the outline when the input is focused */
    text-align: center;
    font-size: ${text.small};
  }

  input[type="text"] {
    -moz-appearance: textfield; /* Firefox */
    max-width: 25px;
    width: 100%;

  }
  
  /* Hide the spin buttons for WebKit browsers */
  input[type="text"]::-webkit-inner-spin-button,
  input[type="text"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

`;


export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0 20px 0;
  vertical-align: middle:

`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 40px 0 0 0;

    button {
      width: 100%;
      font-size: ${text.small};
      padding: 30px 0
    }
`;

export const PaymentContainer = styled.div`
display: flex;
flex-direction: column;
padding: 20px 0;



`;
