import styled, { css } from 'styled-components';
import { text } from '../../utilities/sizes.util';

const subColor = 'grey';
const mainColor = 'black';

const shrinkLabelStyles = css`
  top: -20px;
  margin-left: -5px;
  font-size: ${text.xsmall};
  color: ${mainColor};
`;

export const FormInputLabel = styled.label`
  color: ${subColor};
  font-size: ${text.medium};
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 15px;
  transition: 300ms ease all;
  padding: 0 0 0 10px;

  ${({ shrink }) => shrink && shrinkLabelStyles};
`;

export const Input = styled.input`
  background: none;
  background-color: white;
  color: ${mainColor};
  font-size: ${text.medium};
  padding: 10px 10px 10px 10px;
  display: block;
  width: 100%;
  border: 2px solid ${subColor};
  border-radius: 8px;
  margin: 35px 0;

  &:focus {
    outline: none;
  }

  &:focus ~ ${FormInputLabel} {
    ${shrinkLabelStyles};
  }

`;

export const Group = styled.div`
  position: relative;
  margin: 30px 0;


  input[type="time"]:not(.has-value):not(:focus) {
    color: black;
  }
  input[type="time"]:not(:focus) {
    color: transparent;
  }

  
  

  input[type="date"]::before, input[type='datetime-local']::before {
    color: grey;
    font-size: ${text.medium};
    content: attr(placeholder);
      &label {
        outline: none;
        display: none;
      }
  }
  input[type='date'], input[type='datetime-local'] {
    color: transparent;

    &:focus::before,
    &:valid::before {
      content: '' !important;
    }

    &:not(:focus) + label {
      display: none;
    }
  }

    input[type="date"]:focus,
    input[type="date"]:valid,
    input[type="datetime-local"]:focus,
    input[type="datetime-local"]:valid {
      color: ${mainColor};
      margin-right: 10px;
    }

    input[type="date"]:focus::before,
    input[type="date"]:valid::before,
    input[type="datetime-local"]:focus::before,
    input[type="datetime-local"]:valid::before {
      content: "" !important;
    }
`;


export const TextArea = styled.textarea`
  background: none;
  background-color: white;
  color: ${mainColor};
  font-size: ${text.medium};
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: 2px solid ${subColor};
  border-radius: 8px;
  margin: 40px 0;

  &:focus {
    outline: none;
  }

  &:focus ~ ${FormInputLabel} {
    ${shrinkLabelStyles};
  }
`;
