import React, {useContext} from "react";
import { useNavigate } from "react-router-dom";
import { ResponsiveScreenContext } from "../../contexts/screen.context";
import { RoutNameContext } from "../../contexts/route-name.context";
import { CardContainer, Header, Hero, Footer } from "./card.styles";
import { Button, BUTTON_TYPE_CLASSES } from "../button/button.component";
import { toURLFriendlyString } from "../../utilities/striper.util";


export const CardComponent = ({ header, image, imageName, details, tourName}) => {
    const { currentScreen } = useContext(ResponsiveScreenContext);
    const { routeName, setRouteName } = useContext(RoutNameContext);
    const  navigate  = useNavigate();



    const onClickNavigation = () => {
            if (tourName) {
            setRouteName(tourName)
            const urlFriendlyString = toURLFriendlyString(tourName);
            window.scrollTo(0, 0);
            navigate(`/package/${urlFriendlyString}`);
            }
        };


      return (
        <CardContainer className={currentScreen}>
            {header && 
                <Header>
                    <h3>{header}</h3>
                </Header>
            }
            <Hero>
                <img src={image} alt={`${imageName}`} />
            </Hero>
            <Footer>
            {imageName && 
                    <h5>{imageName}</h5>
                }
              <p>{details}</p>
            </Footer>
                {tourName && 
                    <Button onClick={onClickNavigation} buttonType={BUTTON_TYPE_CLASSES.base}>
                       More details
                    </Button>
                }
        </CardContainer>
      );
  };