import React from 'react';
import { Group, FormInputLabel, Input, TextArea } from './label-and-input.styles'

export const LabelInputComponent = ({ label, textArea, ...otherProps}) => {
    return(
      <div>
        { textArea ? 
        (
          <Group>
            <TextArea {...otherProps}/>
            {label && (
              <FormInputLabel  shrink={otherProps.value.length}>
                {label}
              </FormInputLabel>
            )}
          </Group>
        ):(
        <Group>
            <Input {...otherProps} />
            {label && (
              <FormInputLabel shrink={otherProps.value.length}>
                {label}
              </FormInputLabel>
            )}
          </Group>
        )}
      </div>
    )
  }