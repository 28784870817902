import styled from 'styled-components';
import { text } from '../../utilities/sizes.util';

export const SeparatorComponentContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    

    &.separator {
      .line-with-text {
        height: 2px;
        background-color: rgba(1,1,1,0.20);
      }
    }



    .left1, .right2 {
      height: 5px;
      background-color: deepskyblue;
    }

    .right1, .left2 {
      height: 5px;
      background-color: black;
    }
    .text {
      margin: 0 10px;
      font-size: ${text.medium};
    }

    &.mobile , &.smobile {
      white-space: nowrap;
    }
    

`;