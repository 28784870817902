import React from 'react';
import { useContext } from 'react';
import { ResponsiveScreenContext } from '../../../contexts/screen.context';
import { IsContext } from '../../../contexts/is.context';

import { CloseIconContainer, CloseIcon } from './icon.close.styles'

export const CloseIconComponent = () => {
    const {currentScreen} = useContext(ResponsiveScreenContext)
  const { isMenuOpen, setIsMenuOpen } = useContext(IsContext);

  const toggleIsMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <CloseIconContainer className={currentScreen} onClick={toggleIsMenuOpen}>
        <CloseIcon />
    </CloseIconContainer>
  );
};