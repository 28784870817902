import { createGlobalStyle } from 'styled-components';
import { device, text } from './utilities/sizes.util';

export const GlobalStyle = createGlobalStyle`
	body {
		margin: 0;
		padding: 0;
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}



	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
			monospace;
	}

	a {
		text-decoration: none;
		color: black;
	}

	a:hover {
		color: gainsboro;
	}

	* {
		box-sizing: border-box;
	}

	h1 {
		font-size: ${text.hone};
	}
	h2 {
		font-size: ${text.htwo};
	}
	h3 {
		font-size: ${text.hthree};
	}
	h4 {
		font-size: ${text.hfour};
	}
	h5 {
		font-size: ${text.hfive};
	}
	h6 {
		font-size: ${text.hsix};
	}

	p {
		font-size: ${text.paragraph};
	}



`;