import styled from 'styled-components';
import { text, icon } from '../../../utilities/sizes.util';

export const StyledUL = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  text-decoration: none;


  li {
    margin-left: 20px;
    font-size: ${text.list};
    white-space: nowrap;
  }
`;