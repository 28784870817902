import React, {useContext} from 'react';
import { ResponsiveScreenContext } from '../../../contexts/screen.context';
import { StyledUL } from './nav-list-header.header.styles';
import { ListComponent } from '../../list/list.component';
import { PackagesListComponent } from '../../list/packages.list.component';

export const ListHeaderComponent = ({ layout }) => {
  const {currentScreen} = useContext(ResponsiveScreenContext)

  const handleClick = () => {
    window.scrollTo(0, 0);
  }

  return (
    <StyledUL layout={layout}>
          <PackagesListComponent linkTo="/packages" text="Packages & Tours" onClick={handleClick} />
          <ListComponent linkTo="/aboutus" text="About us" onClick={handleClick} />
          <ListComponent linkTo="/contacts" text="Contacts" onClick={handleClick} />
    </StyledUL>
  );
};

