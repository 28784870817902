import styled from 'styled-components';
import { icon } from '../../utilities/sizes.util';

export const AcceptedPaymentContiner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
    gap: 40px;


`;


export const CardContainer = styled.div`  
    font-size: ${icon.xlarge};
`;

