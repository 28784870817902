import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Suspense } from 'react';
import Spinner from './components/spinner/spinner.component';
import { Header } from './components/header/header.component'
import { Home } from './routes/home/home.routes';
import { SignIn } from './routes/authentication/sign-in.authentication.route';
import { Packages } from './routes/packages/package.route';
import { Tour } from './routes/tour/tour.route';
import { Aboutus } from './routes/aboutus/aboutus.route';
import { Contacts } from './routes/contacts/constacts.route';

import { GlobalStyle } from './global.styles';



const App = () => {
  return (
    <Suspense fallback={<Spinner />}>
    <Routes>
      <Route path='/' element={<Header />}>
        <Route index element={<Home />} />
        <Route path='user'>
          <Route path='sign-in' element={<SignIn />} />
        </Route>
        <Route path='packages' element={<Packages />} />
        <Route path='package/:tourName'  element={<Tour />} />
        <Route path='aboutus' element={<Aboutus />} />
        <Route path='contacts' element={<Contacts />} />
      </Route>
    </Routes>
    <GlobalStyle />
    </Suspense>
  );
};

export default App;