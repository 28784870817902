import React, {useContext} from 'react';
import { Table, THead, TH, TR, TD,
  TableComponentContainer,
  InclutionTableContainer,
  RatePerPaxAndTotalContainer,
  TableComponentDesktopContainer,
  TableComponentMobileContainer
} from './table.styles';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import { IsContext } from '../../contexts/is.context';

const InclutionTableDesktop = ({selectedTour, selectedPackage}) => {
  return (
    <Table className='table-inclusions'>
      <THead className='thead-inclusions'>
        <TR className='tr-title'>
          <TH className='inclusion-title'>Tour Details</TH>
        </TR>
      </THead>
      <tbody className='tbody-inclutions'>
          <TR className='tr-details'>
            <TD className='td-inclutionsinclusion-details'>
              <h4>
                Inclusions:
              </h4>
            <ul>
            {selectedTour.inclusions.map((inclusion, index) => (
              <li key={index}>{inclusion}</li>
            ))}
            </ul>
            {selectedPackage.addons && (
                <div> {/* Add a wrapping element */}
                  <h4>
                    Add-ons:
                  </h4>
                  <ul>
                    {selectedPackage.addons.map((addon, index) => (
                      <li key={index}> {addon.addonName} ({ addon.addonRate})
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </TD>
          </TR>
      </tbody>
  </Table>
  )
}

const InclutionTableMobile = ({selectedTour, selectedPackage}) => {
  return (
    <Table className='table-inclusions'>
      <tbody className='tbody-inclutions'>
          <TR className='tr-details'>
            <TD className='td-inclutionsinclusion-details'>
              <h4>
                Inclusions:
              </h4>
            <ul>
            {selectedTour.inclusions.map((inclusion, index) => (
              <li key={index}>{inclusion}</li>
            ))}
            </ul>
            {selectedPackage.addons && (
                <div> {/* Add a wrapping element */}
                  <h4>
                    Add-ons:
                  </h4>
                  <ul>
                    {selectedPackage.addons.map((addon, index) => (
                      <li key={index}> {addon.addonName} ({ addon.addonRate})
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </TD>
          </TR>
      </tbody>
  </Table>
  )
}

const RatePerPaxAndTotal = ({selectedTour}) => {
  return (
    <Table className='table-pax-rate-total'>
    <THead className='thead-pax-rate-total'>
      <TR>
        <TH className='number-of-person'>No. of Person</TH>
        <TH className='rate-per-person'>Rate per Person</TH>
        <TH className='total-to-pay'>Total to Pay</TH>
      </TR>
    </THead>
    <tbody className='tbody-pax-rate-total'>
      {selectedTour.prices.map((price, index) => (
        <TR className='tr-pax-rate-total' key={index}>
          <TD className='td-pax'>{price.pax}</TD>
          <TD className='td-rate'>{price.ratePerPerson}</TD>
          <TD className='td-total'>{price.pax * price.ratePerPerson}</TD>
        </TR>
      ))}
    </tbody>
  </Table>
  )
}

export const TableComponent = ({selectedPackage, selectedTour, setSelectedTour }) => {
  const {currentScreen} = useContext(ResponsiveScreenContext);
  const { isDetails } = useContext(IsContext);

  return (
    <TableComponentContainer className={currentScreen}>
      {currentScreen === 'mobile' || currentScreen === 'smobile' ? (
         <TableComponentMobileContainer>
          {isDetails ? (
            <InclutionTableContainer>
              <InclutionTableMobile selectedTour={selectedTour} selectedPackage={selectedPackage}/>
            </InclutionTableContainer>

          ):(
          <RatePerPaxAndTotalContainer>
            <RatePerPaxAndTotal selectedTour={selectedTour}/>
          </RatePerPaxAndTotalContainer>
          )}
        </TableComponentMobileContainer>
      ):(
        <TableComponentDesktopContainer>
            <RatePerPaxAndTotalContainer>
              <RatePerPaxAndTotal selectedTour={selectedTour}/>
            </RatePerPaxAndTotalContainer>
            <InclutionTableContainer>
              <InclutionTableDesktop selectedTour={selectedTour} selectedPackage={selectedPackage}/>
            </InclutionTableContainer>
        </TableComponentDesktopContainer>
      )}

    </TableComponentContainer>
  );
};