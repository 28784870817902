export const packages = [
  {
      "packageName": "Bohol Bohol Countryside Tours",
      "tours": [
          {
              "tourName": "Bohol Countryside Tour A",
              "inclusions": [
                  "Loboc/Loay river cruise Buffet Lunch included",
                  "Blood Compact Shine",
                  "Chocolate Hills and Obsevatory Deck",
                  "Butterfly Conservatory",
                  "Sevilla Hanging Bridge",
                  "Bilar Man-Made Forest",
                  "Baclayon Church",
                  "Souvernier Shops",
                  "Entrance and parking fees",
                  "Department of Tourism accredited aircon transportation",
                  "Hotel pick-up/drop-off",
                  "Tour driver will serve as a local tourist guide",
                  "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
              ],
              "durations": "8 hours duration",
              "prices": [
                  {
                    "pax": 2,
                    "ratePerPerson": 6000,
                  },
                  {
                    "pax": 3,
                    "ratePerPerson": 4385,
                  },
                  {
                    "pax": 4,
                    "ratePerPerson": 3550,
                  },
                  {
                    "pax": 5,
                    "ratePerPerson": 3150,
                  },
                  {
                    "pax": 6,
                    "ratePerPerson": 3135,
                  },
                  {
                    "pax": 7,
                    "ratePerPerson": 2907,
                  },
                  {
                    "pax": 8,
                    "ratePerPerson": 2700,
                  },
                  {
                    "pax": 9,
                    "ratePerPerson": 2500,
                  },
                  {
                    "pax": 10,
                    "ratePerPerson": 2450,
                  },
                  {
                    "pax": 11,
                    "ratePerPerson": 2420,
                  },
                  {
                    "pax": 12,
                    "ratePerPerson": 2400,
                  }
                ],
              "image":
                {
                  "name": "Tarsier Sanctuary",
                  "source": require("../assets/tour_photos/Tarsier_Sanctuary.jpg"),
                  "info": "The tarsier is considered as the world's smallest primate. An endangered species, the tarsier is a tree-climbing and insect-eating primate with large eyes."
                },       
          },
          {
              "tourName": "Bohol Countryside Tour B",
              "inclusions": [
                  "Blood Compact Shine",
                  "Chocolate Hills and Obsevatory Deck",
                  "Butterfly Conservatory",
                  "Sevilla Hanging Bridge",
                  "Bilar Man-Made Forest",
                  "Baclayon Church",
                  "Souvernier Shops",
                  "Entrance and parking fees",
                  "Department of Tourism accredited aircon transportation2",
                  "Hotel pick-up/drop-off",
                  "Tour driver will serve as a local tourist guide",
                  "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
              ],
              "durations": "8 hours duration",
              "prices": [
                  {
                    "pax": 2,
                    "ratePerPerson": 5200
                  },
                  {
                    "pax": 3,
                    "ratePerPerson": 3534
                  },
                  {
                    "pax": 4,
                    "ratePerPerson": 2700
                  },
                  {
                    "pax": 5,
                    "ratePerPerson": 2300
                  },
                  {
                    "pax": 6,
                    "ratePerPerson": 2034
                  },
                  {
                    "pax": 7,
                    "ratePerPerson": 1914
                  },
                  {
                    "pax": 8,
                    "ratePerPerson": 1763
                  },
                  {
                    "pax": 9,
                    "ratePerPerson": 1645
                  },
                  {
                    "pax": 10,
                    "ratePerPerson": 1550
                  },
                  {
                    "pax": 11,
                    "ratePerPerson": 1473
                  },
                  {
                    "pax": 12,
                    "ratePerPerson": 1410
                  }
                ],
              "image":{
                "name": "Chocolate Hills",
                "source": require("../assets/tour_photos/Chocolate_Hills.jpg"),
                "info": "The jewel of Bohol tourism, the Chocolate Hills is considered as one of the world's famous natural wonders. Thousands of brown and green hills are spread out all over the place as far as your eyes can see. Definitely a must-visit for every tourist and traveller to Bohol. The Chocolate Hills are a group of unusually shaped hills located in the middle of the island of Bohol in Philippines. This extraordinary landscape is unique to this small island."
              }
          },
          {
              "tourName": "Bohol Countryside Tour C",
              "inclusions": [
                  "Sikatunas Mirror of The World",
                  "Loboc/Loay river cruise with Buffe Lunch",
                  "Chocolate Hills and Observatory Deck",
                  "Butterfly Conservatory",
                  "Bilar Man-Made Forrest",
                  "Sevilla Hanging Bridge",
                  "Baclayon Church",
                  "Blood Compact Shrine",
                  "Souvernir Shops",
                  "Entrance and parking fees",
                  "Department of Tourism accredited aircon transportation",
                  "Hotel pick-up/drop-off",
                  "Tour driver will serve as a local tourist guide",
                  "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
              ],
              "durations": "8 hours and 30 minutes duration",
              "prices": [
                {
                  "pax": 2,
                  "ratePerPerson": 6250
                },
                {
                  "pax": 3,
                  "ratePerPerson": 4617
                },
                {
                  "pax": 4,
                  "ratePerPerson": 3800
                },
                {
                  "pax": 5,
                  "ratePerPerson": 3410
                },
                {
                  "pax": 6,
                  "ratePerPerson": 3066
                },
                {
                  "pax": 7,
                  "ratePerPerson": 2821
                },
                {
                  "pax": 8,
                  "ratePerPerson": 2625
                },
                {
                  "pax": 9,
                  "ratePerPerson": 2584
                },
                {
                  "pax": 10,
                  "ratePerPerson": 2550
                },
                {
                  "pax": 11,
                  "ratePerPerson": 2550
                },
                {
                  "pax": 12,
                  "ratePerPerson": 2550
                }
              ],
              "image": { 
                "name": "Blood Compact Shrine",
                "source": require("../assets/tour_photos/Blood_Compact_Shrine.jpg"),
                "info": "The Blood Compact Monument is an artwork that was made to honor the first international treaty between the Philippines and Spain. The memorial stands in an area along the side of what is now known as Tagbilaran East Road in the Bool District. Its current spot holds historical significance since it is where  Miguel Lopez de Legaspi and Rajah Sikatuna performed a blood compact on March 16, 1565. This gesture supposedly signified peace and friendship between the foreigners and the natives of Bohol."
              }
          }
      ],
      "details": "Rent Van with Licensed & Polite tour Driver Guide/Photographer. Pick-up and drop-off Tagbilaran or Panglao accommodation or Sea/Air-Port (look for driver bring sign with your name in Arrival Area Outside). Entrance and parking fee included in Package (excluding optional spot) Fully Air conditioned Vehicle transportation and gasoline. Bohol Countryside day tour package that lets you visit the tourist spots of Bohol countryside (scroll down for more information).",
      "photos": [
          {
            "name": "Tarsier Sanctuary",
            "source": require("../assets/tour_photos/Tarsier_Sanctuary.jpg"),
            "info": "The tarsier is considered as the world's smallest primate. An endangered species, the tarsier is a tree-climbing and insect-eating primate with large eyes."
          },
          {
            "name": "Chocolate Hills",
            "source": require("../assets/tour_photos/Chocolate_Hills.jpg"),
            "info": "The jewel of Bohol tourism, the Chocolate Hills is considered as one of the world's famous natural wonders. Thousands of brown and green hills are spread out all over the place as far as your eyes can see. Definitely a must-visit for every tourist and traveller to Bohol. The Chocolate Hills are a group of unusually shaped hills located in the middle of the island of Bohol in Philippines. This extraordinary landscape is unique to this small island."
          },
          { 
              "name": "Blood Compact Shrine",
              "source": require("../assets/tour_photos/Blood_Compact_Shrine.jpg"),
              "info": "The Blood Compact Monument is an artwork that was made to honor the first international treaty between the Philippines and Spain. The memorial stands in an area along the side of what is now known as Tagbilaran East Road in the Bool District. Its current spot holds historical significance since it is where  Miguel Lopez de Legaspi and Rajah Sikatuna performed a blood compact on March 16, 1565. This gesture supposedly signified peace and friendship between the foreigners and the natives of Bohol."
          },
          {
              "name": "Baclayon Church",
              "source": require("../assets/tour_photos/Baclayon Church.jpg"),
              "info": "The Baclayon Church is the oldest Catholic stone-built church in the Philippines that provides a rich early history of the province of Bohol. It was constructed during the Spanish occupation as the very first Spanish Missionaries or Doctrineros settled in the area during the late 15th Century. Today, the original stone church can still be seen at Tagbilaran, Bohol."
          },
          {
              "name": "Butterfly Garden",
              "source": require("../assets/tour_photos/Butterfly_Garden.jpg"),
              "info": "The Bohol Butterfly Conservation Center or the Butterfly Garden is home to hundreds of butterfly species. You can see the different stages of the butterfly's development as well as specimens of live and preserved butterflies and moths."
          },
          {
              "name": "Aproniana Giftshop",
              "source": require("../assets/tour_photos/Aproniana_Giftshop.jpg"),
              "info": "After a tiresome but enjoyable day, a good way to cap it off is to buy souvenirs and local items at Aproniana Souvenir Shop. Buy some gifts to bring home to your friends and family, or simply as a souvenir or remembrance of your Bohol vacation."
          },
          {
              "name": "ATV Adventures",
              "source": require("../assets/tour_photos/ATV_Adventures.jpg"),
              "info": "Take a cruising tour around the foothills of the natural wonder, Chocolate Hills."
          },
          {
              "name": "Zip-lining",
              "source": require("../assets/tour_photos/ZIP-line.jpg"),
              "info": "Experience the thrill of flying through the air on a zip-line tour. "
          }
      ],
      "addons": [
        {
          "addonName": "ATV Adventures ",
          "addonPrice": "1200",
          "addonRate": "1200/hour"
        },
        { 
          "addonName": "Cable Car and Zip Line",
          "addonPrice": "450",
          "addonRate": "450/pax"
        }
      ]
  },
  {
      "packageName": "Bohol Bohol Panglao Tours",
      "tours": [
          {
              "tourName": "Bohol Panglao Tour A",
              "inclusions": [
                  "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
                  "Panglao Church and Watch Tower",
                  "Seashell Museum",
                  "Hinagdanan Cave",
                  "Bohol Bee Farm",
                  "Dauis Church and Miracle Well",
                  "Moadto Strip Mall",
                  "Side Trip to Mist Café & restaurant / La Vara or Molly Wa / Molly Bistro",
                  "Alona Beach",
                  "2:30 PM Estemated time End of the Tour. Drop off to Hotel / Resort"
              ],
              "durations": "5-6 hours",
              "prices": [
                  {
                    "pax": 2,
                    "ratePerPerson": 4250
                  },
                  {
                    "pax": 3,
                    "ratePerPerson": 2916
                  },
                  {
                    "pax": 4,
                    "ratePerPerson": 2250
                  },
                  {
                    "pax": 5,
                    "ratePerPerson": 2150
                  },
                  {
                    "pax": 6,
                    "ratePerPerson": 1833
                  },
                  {
                    "pax": 7,
                    "ratePerPerson": 1607
                  },
                  {
                    "pax": 8,
                    "ratePerPerson": 1438
                  },
                  {
                    "pax": 9,
                    "ratePerPerson": 1305
                  },
                  {
                    "pax": 10,
                    "ratePerPerson": 1200
                  },
                  {
                    "pax": 11,
                    "ratePerPerson": 1159
                  },
                  {
                    "pax": 12,
                    "ratePerPerson": 1083
                  }
                ],
              "image": {
                "name": "Alona Beach",
                "source": require("../assets/tour_photos/Alona_Beach.jpg"),
                "info": "Welcome to Alona Beach!Alona Beach is a small stretch of tropical paradise on Panglao Island, Bohol in the Philippines. It is quickly becoming one of the top travel destinations in the Philippines because of it's wonderful white sand beach, beautiful blue waters, world class diving and tours."
    
              }
          },
          {
              "tourName": "Bohol Panglao Tour B",
              "inclusions": [
                  "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
                  "Sikatuna Mirror Of The World",
                  "Dauis Church",
                  "St.Joseph Cathedral",
                  "Bird feeding @ Tagbilaran plaza",
                  "Hinagdanan Cave",
                  "Bohol Bee Farm",
                  "Swimming at Dumaluan Beach (Upon request, add P 100 per person)",
                  "Panglao Church and Watch Tower",
                  "Mist Restaurant and Cafe",
                  "Modala Strip Mall",
                  "2:30 PM Estemated time End of the Tour. Drop off to Hotel / Resort"
              ],
              "durations": "5-6 hours",
              "prices": [
                  {
                    "pax": 2,
                    "ratePerPerson": 4650
                  },
                  {
                    "pax": 3,
                    "ratePerPerson": 3267
                  },
                  {
                    "pax": 4,
                    "ratePerPerson": 2575
                  },
                  {
                    "pax": 5,
                    "ratePerPerson": 2200
                  },
                  {
                    "pax": 6,
                    "ratePerPerson": 1983
                  },
                  {
                    "pax": 7,
                    "ratePerPerson": 1771
                  },
                  {
                    "pax": 8,
                    "ratePerPerson": 1613
                  },
                  {
                    "pax": 9,
                    "ratePerPerson": 1489
                  },
                  {
                    "pax": 10,
                    "ratePerPerson": 1390
                  },
                  {
                    "pax": 11,
                    "ratePerPerson": 1309
                  },
                  {
                    "pax": 12,
                    "ratePerPerson": 1200
                  }
                ],
              "image": {
                "name": "Dauis Church",
                "source": require("../assets/tour_photos/Dauis_Church.jpg"),
                "info": "The church in Dauis is one of the sophisticated and complex structures of its kind in Bohol. Built according to a plan for basilicas, that is, with a high central nave flanked by lower aisles, certain innovations have been added such as a pair of extensions to form “transepts “. The transept “arms” are unique, as their walls are built diagonal to the nave, instead of the common right-angle configuration. The crossing is topped by a cupola in the shape of a low octagonal pyramid."
  
            }
          },
          {
              "tourName": "Bohol Panglao Tour C",
              "inclusions": [
                  "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
                  "Hinagdanan Cave",
                  "Dauis Church",
                  "Alona Beach",
                  "Bee Farm",
                  "Floating Restaurant Loboc/Loay river cruise Buffet Lunch included",
                  "2:30 PM Estemated time End of the Tour. Drop off to Hotel / Resort"
              ],
              "durations": "Not Specified",
              "prices": [
                  {
                    "pax": 2,
                    "ratePerPerson": 5050
                  },
                  {
                    "pax": 3,
                    "ratePerPerson": 3783
                  },
                  {
                    "pax": 4,
                    "ratePerPerson": 3150
                  },
                  {
                    "pax": 5,
                    "ratePerPerson": 2770
                  },
                  {
                    "pax": 6,
                    "ratePerPerson": 2516
                  },
                  {
                    "pax": 7,
                    "ratePerPerson": 2357
                  },
                  {
                    "pax": 8,
                    "ratePerPerson": 2200
                  },
                  {
                    "pax": 9,
                    "ratePerPerson": 2094
                  },
                  {
                    "pax": 10,
                    "ratePerPerson": 2010
                  },
                  {
                    "pax": 11,
                    "ratePerPerson": 1941
                  },
                  {
                    "pax": 12,
                    "ratePerPerson": 1883
                  }
                ],
              "image": {
                "name": "Hinagdanan Cave",
                "source": require("../assets/tour_photos/Hinagdanan_Cave.jpg"),
                "info": "One of the most renowned caves in Bohol is the Hinagdanan Cave. Looking around the cave one can see stalactites hanging from the ceiling and protruding stalagmites on the ground. The cave has two holes aboveground gives a streak of light towards the upper left side of the cave. Hinagdanan is a karst cave which means it is made of limestones. Boasting several stalactite and stalagmite formations parallel to each other and a fresh water lagoon that is about 10 to 12 meters deep. "
  
            }
          },
          {
            "tourName": "Bohol Panglao Tour D",
            "inclusions": [
                "Hinagdaan Cave Dauis",
                "Panglao Beach",
                "Alona Beach",
                "Dumaluan Beach",
                "Panglao Island Hopping",
                "Bohol Bee Farm",
                "All Entrance fees",
                "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport"
            ],
            "durations": "5-6 hours",
            "prices": [
              {
                "pax": 2,
                "ratePerPerson": 6000
              },
              {
                "pax": 3,
                "ratePerPerson": 4666
              },
              {
                "pax": 4,
                "ratePerPerson": 3875
              },
              {
                "pax": 5,
                "ratePerPerson": 3400
              },
              {
                "pax": 6,
                "ratePerPerson": 3084
              },
              {
                "pax": 7,
                "ratePerPerson": 2857
              },
              {
                "pax": 8,
                "ratePerPerson": 2687
              },
              {
                "pax": 9,
                "ratePerPerson": 2556
              },
              {
                "pax": 10,
                "ratePerPerson": 2450
              },
              {
                "pax": 11,
                "ratePerPerson": 2364
              },
              {
                "pax": 12,
                "ratePerPerson": 2250
              }
            ],
            "image": {
              "name": "Alona Beach",
              "source": require("../assets/tour_photos/Alona_Beach.jpg"),
              "info": "Welcome to Alona Beach!Alona Beach is a small stretch of tropical paradise on Panglao Island, Bohol in the Philippines. It is quickly becoming one of the top travel destinations in the Philippines because of it's wonderful white sand beach, beautiful blue waters, world class diving and tours."
          
              }
            }
      ],
      "details": "Rent Van with Licensed & Polite tour Driver Guide/Photographer. Pick-up and drop-off Tagbilaran or Panglao accommodation or Sea/Air-Port (look for driver bring sign with your name in Arrival Area Outside). Entrance and parking fee included in Package. Fully Air conditioned Vehicle transportation and gasoline. Bohol Bohol Panglao Tour package that lets you visit the tourist spots at Panglao (scroll down for more information). Inclusions: Pick-up and drop-off Tagbilaran or Panglao accommodation, Fully Air conditioned Vehicle transportation and gasoline.  Entrance fees to all Tourist destinations. Duration of tour up to 5-6 hours.",
      "photos": [
          {
            "name": "Alona Beach",
            "source": require("../assets/tour_photos/Alona_Beach.jpg"),
            "info": "Welcome to Alona Beach!Alona Beach is a small stretch of tropical paradise on Panglao Island, Bohol in the Philippines. It is quickly becoming one of the top travel destinations in the Philippines because of it's wonderful white sand beach, beautiful blue waters, world class diving and tours."

          },
          {
              "name": "Dauis Church",
              "source": require("../assets/tour_photos/Dauis_Church.jpg"),
              "info": "The church in Dauis is one of the sophisticated and complex structures of its kind in Bohol. Built according to a plan for basilicas, that is, with a high central nave flanked by lower aisles, certain innovations have been added such as a pair of extensions to form “transepts “. The transept “arms” are unique, as their walls are built diagonal to the nave, instead of the common right-angle configuration. The crossing is topped by a cupola in the shape of a low octagonal pyramid."

          },
          {
              "name": "Hinagdanan Cave",
              "source": require("../assets/tour_photos/Hinagdanan_Cave.jpg"),
              "info": "One of the most renowned caves in Bohol is the Hinagdanan Cave. Looking around the cave one can see stalactites hanging from the ceiling and protruding stalagmites on the ground. The cave has two holes aboveground gives a streak of light towards the upper left side of the cave. Hinagdanan is a karst cave which means it is made of limestones. Boasting several stalactite and stalagmite formations parallel to each other and a fresh water lagoon that is about 10 to 12 meters deep. "

          },
          {
              "name": "Nova Shell Museum",
              "source": require("../assets/tour_photos/Shell_Museum.jpg"),
              "info": "The Nova Shell Museum is one of the biggest shell collections in the world where you can find the world’s smallest seashell, Rotaovula Hirohitoi. The shell was named after Emperor Hirohito of Japan.  The shell itself is so small that the use of a magnifying glass is recommended to see the shell in its glory."

          },
          {
              "name": "Loboc River Floating Restaurant",
              "source": require("../assets/tour_photos/Floating.jpg"),
              "info": "Enjoy an idyllic cruise aboard a floating restaurant along the Loboc River. Be enthralled by the captivating view of tropical palms and trees while being treated to a sumptuous lunch and music."

          },
          {
              "name": "Sikatuna Mirror of the World",
              "source": require("../assets/tour_photos/Mirror_Of_The_World.jpg"),
              "info": "Visitors can expect to see world-famous landmarks at Mirror of the World and Botanical Garden. These landmarks include the Christ the Redeemer in Rio de Janeiro, the Eiffel Tower in Paris, the Golden Gate Bridge of California, the Merlion in Singapore, and the Statue of Liberty in New York."

          }
      ]
  },
  {
      "packageName": "Bohol Bohol Anda Tours",
      "tours": [
          {
              "tourName": "Bohol Anda Tour A",
              "inclusions": [
                  "Pickup go to Jagna Mayana Strawberry Farm 5:30 AM",
                  "Arrival Mayana Strawberry Farm 7:15",
                  "Departure to Can-Umatad Rice Terraces in Candijay 8:30",
                  "Arrivel Candijay Rice Terraces 10:00",
                  "Procced to Can-Umantad Falls 11:00",
                  "Arrival Can-Umatad Falls 11:30",
                  "Departure  Going to Anda White Beach 12:30 PM",
                  "The estimated arrival time at Anda Quinale Beach is 1:30 pm.  There will be lunch available for purchase at your own expense.",
                  "Go to Anda Cave pool 3:00",
                  "Departure going back to Resort Hotel 5:00",
                  "End of Tour and Arrival Resort Hotel 7:30 pm.",
              ],
              "durations": "10-12 hours",
              "prices": [
                  {
                    "pax": 2,
                    "ratePerPerson": 7000
                  },
                  {
                    "pax": 3,
                    "ratePerPerson": 5000
                  },
                  {
                    "pax": 4,
                    "ratePerPerson": 4167
                  },
                  {
                    "pax": 5,
                    "ratePerPerson": 3400
                  },
                  {
                    "pax": 6,
                    "ratePerPerson": 3000
                  },
                  {
                    "pax": 7,
                    "ratePerPerson": 2714
                  },
                  {
                    "pax": 8,
                    "ratePerPerson": 2500
                  },
                  {
                    "pax": 9,
                    "ratePerPerson": 2334
                  },
                  {
                    "pax": 10,
                    "ratePerPerson": 2200
                  },
                  {
                    "pax": 11,
                    "ratePerPerson": 2090
                  },
                  {
                    "pax": 12,
                    "ratePerPerson": 2000
                  }
                ],
              "image": {
                "name": "Strawberry Farm Mayana",
                "source": require("../assets/tour_photos/Strawberry_Farm.jpg"),
                "info": "Strawberry Farm Mayana the Bohol Organic and Strawberry Farm in Brgy. Mayana, Jagna in the province of Bohol offers a wide area of sweet and succulent strawberries available for picking. It is believed that growing of strawberries in the elevated Brgy. Mayana is probably ideal because of its cool temperature.  It is advisable to visit the place early in the morning for higher chances of picking more strawberries. Not just that, there are times when the place is cloaked with cold fog so you might want to experience that. Aside from strawberries, you can also see other organic vegetables like lettuce, tomatoes, pepper and a lot more. Note that you have to pay for what you have harvested. While here, guests are treated with the grand view of the distant verdant hills. Bohol’s highest peak is visible from Bohol Organic and Strawberry Farm. Who would have thought that picking strawberries can be this relaxing? Make sure to bring your family and friends for a good quality time. "
              }  
          },
          {
              "tourName": "Bohol Anda Tour B",
              "inclusions": [
                  "Pick-up go to Can-Umatad Rice Terraces in Candijay 6:00 am",
                  "Arrival Candijay Riceterraces 9:00 am",
                  "Proceed to Can-Umatad Falls 9:30 am",
                  "Swimming and take pictures at the Falls 10:00 am",
                  "Departure going to Anda White Beach 12:00 pm",
                  "Arrival in Anda Quinale White Beach Eat lunch  and Enjoy swimming 1:00 PM",
                  "Go to Anda Cave Pool 3:00",
                  "Departure going Back to Resort 5:00 pm",
                  "Estimated end of Tour and Arrival Resort 8:00 pm (you may drop at Jagna town if  you Want buy Calamay)."
              ],
              "durations": "10-12 hours",
              "prices": [
                  {
                    "pax": 2,
                    "ratePerPerson": 5500
                  },
                  {
                    "pax": 3,
                    "ratePerPerson": 4000
                  },
                  {
                    "pax": 4,
                    "ratePerPerson": 3250
                  },
                  {
                    "pax": 5,
                    "ratePerPerson": 2800
                  },
                  {
                    "pax": 6,
                    "ratePerPerson": 2500
                  },
                  {
                    "pax": 7,
                    "ratePerPerson": 2285
                  },
                  {
                    "pax": 8,
                    "ratePerPerson": 2125
                  },
                  {
                    "pax": 9,
                    "ratePerPerson": 2000
                  },
                  {
                    "pax": 10,
                    "ratePerPerson": 1900
                  },
                  {
                    "pax": 11,
                    "ratePerPerson": 1818
                  },
                  {
                    "pax": 12,
                    "ratePerPerson": 1750
                  }
                ],
              "image": {
                "name": "Can-Umantad Falls",
                "source": require("../assets/tour_photos/Can_Umatad_Falls.jpg"),
                "info": "Can-Umantad Falls in Candijay is one of the most impressive waterfalls you will find on the incredible island of Bohol, Philippines! There are also two other great attractions to visit in Candijay, which will make it a great trip. Stop off to see the Cadapdapan Rice Terraces and cool off at Canawa Cold Spring afterward."
              }
                
          }
      ],
      "details": "Rent Van with Licensed & Polite tour Driver Guide/Photographer. Pick-up and drop-off Tagbilaran or Panglao accommodation or Sea/Air-Port (look for driver bring sign with your name in Arrival Area Outside). Entrance and parking fee included in Package. Fully Air conditioned Vehicle transportation and gasoline. Bohol Bohol Anda Tour package that lets you visit the tourist spots at Anda (scroll down for more information).",
      "photos": [
        {
          "name": "Strawberry Farm Mayana",
          "source": require("../assets/tour_photos/Strawberry_Farm.jpg"),
          "info": "Strawberry Farm Mayana the Bohol Organic and Strawberry Farm in Brgy. Mayana, Jagna in the province of Bohol offers a wide area of sweet and succulent strawberries available for picking. It is believed that growing of strawberries in the elevated Brgy. Mayana is probably ideal because of its cool temperature.  It is advisable to visit the place early in the morning for higher chances of picking more strawberries. Not just that, there are times when the place is cloaked with cold fog so you might want to experience that. Aside from strawberries, you can also see other organic vegetables like lettuce, tomatoes, pepper and a lot more. Note that you have to pay for what you have harvested. While here, guests are treated with the grand view of the distant verdant hills. Bohol’s highest peak is visible from Bohol Organic and Strawberry Farm. Who would have thought that picking strawberries can be this relaxing? Make sure to bring your family and friends for a good quality time. "
        },
        {
          "name": "Can-Umantad Falls",
          "source": require("../assets/tour_photos/Can_Umatad_Falls.jpg"),
          "info": "Can-Umantad Falls in Candijay is one of the most impressive waterfalls you will find on the incredible island of Bohol, Philippines! There are also two other great attractions to visit in Candijay, which will make it a great trip. Stop off to see the Cadapdapan Rice Terraces and cool off at Canawa Cold Spring afterward."
        },
        {
          "name": "Anda White Beach",
          "source": require("../assets/tour_photos/Anda_Beach.jpg"),
          "info": "Stop at Anda White Beach Resort to discover the wonders of Bohol. The three-kilometer stretch of powdery white sand is often likened to the shores of the world-famous Boracay Island. Along with its smooth shore, Anda Beach also boasts clear waters that are home to an ecosystem teeming with marine life.  While it is known for its beaches, Anda is also famous for its deep blue cave pools such as Combento, Cateres, Kaligoon, Tiburako, East Coast and Cabagnow. The last cave pool is said to be the “biggest and most beautiful.” These cave pools also provide great conditions for swimmers."
        },
        {
          "name": "Cave Pool of Anda",
          "source": require("../assets/tour_photos/Anda_Cave_Pool.jpg"),
          "info": "Aside from the fine white sand beaches in Quinale, the Cave Pools of Anda are becoming common destinations in the southeastern part of Bohol for adventure seekers. Due to the large percentage of land underlain by limestones, cave pools or basically limestones filled with spring water forming pools are common sights in Anda. The area is underlain by the Middle Miocene Anda Limestone belonging to the Sierra Bullones Limestone Formation. Years of dissolution and weathering of this limestone body resulted in karst features like these caves."
        }
      ]
  },
  {
      "packageName": "Bohol Danao Extreme Tours",
      "tours": [
          {
              "tourName": "Bohol Danao Adventure Park",
              "inclusions": [
                "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
                  "ETA Sea of Clouds Area 5:30",
                  "Departure go to Bohol Danao Adventure Park 7:00",
                  "ETA Danao Eco-Park 9:00 ",
                  "Arrival the Bohol Danao Adventure Park 9:30 (estemated time at Park 7 hours).",
                  "Departure going Back to the Resort 4:30 PM",
                  "Estemated end of the Tour 6:30"
              ],
              "durations": "Up to 10 hours",
              "prices": [
                {
                  "pax": 2,
                  "ratePerPerson": 5100
                },
                {
                  "pax": 3,
                  "ratePerPerson": 3600
                },
                {
                  "pax": 4,
                  "ratePerPerson": 2700
                },
                {
                  "pax": 5,
                  "ratePerPerson": 2260
                },
                {
                  "pax": 6,
                  "ratePerPerson": 1933
                },
                {
                  "pax": 7,
                  "ratePerPerson": 1686
                },
                {
                  "pax": 8,
                  "ratePerPerson": 1525
                },
                {
                  "pax": 9,
                  "ratePerPerson": 1388
                },
                {
                  "pax": 10,
                  "ratePerPerson": 1290
                },
                {
                  "pax": 11,
                  "ratePerPerson": 1218
                },
                {
                  "pax": 12,
                  "ratePerPerson": 1166
                }
              ],
              "image": {
                "name": "Sea of Clouds",
                "source": require("../assets/tour_photos/Sea_Of_Clouds.jpg"),
                "info": "Up in the mountains of Danao in central Bohol there is a viewpoint aptly known as Bohol Sea of Clouds, or Mt. Puntaas. With a viewing area over rice terraces tumbling down into the clouds and an epic ride along Bohol’s countryside sprawling with nature, viewpoints won’t get better than this!"
              }
          }
      ],
      "details": "Bohol Bohol Danao Adventure Park tour package that lets you visit the tourist spots at Danao. The Bohol Danao Adventure Park is where all the exciting action and adventure starts. You can engage in extreme adventure such as the plunge, ziplining, caving, cable car rides, rappelling, and kayaking. Activities own expense. (scroll down for more information). Inclusions: Pick-up and drop-off Tagbilaran or Panglao accommodation, Fully Air conditioned Vehicle transportation and gasoline.  Entrance fees to all Tourist destinations. Duration of tour up to 10 hours.",
      "photos": [
        {
          "name": "Sea of Clouds",
          "source": require("../assets/tour_photos/Sea_Of_Clouds.jpg"),
          "info": "Up in the mountains of Danao in central Bohol there is a viewpoint aptly known as Bohol Sea of Clouds, or Mt. Puntaas. With a viewing area over rice terraces tumbling down into the clouds and an epic ride along Bohol’s countryside sprawling with nature, viewpoints won’t get better than this!"
        },
        {
          "name": "Bohol Danao Adventure Park",
          "source": require("../assets/tour_photos/Adventure_Park.jpg"),
          "info": "If you are hankering for an exciting and breathtaking adventure, maybe the Danao Extreme Adventure Tour will help you feel the adrenalin rush! Experience a different kind of excitement with all sorts of extreme activities such as ziplining, rappelling, kayaking, cave trekking and root climbing. Try this one-of-a-kind adventure if you are tired of the usual tour activities. The Danao Extreme Adventure Tour is definitely not for the faint of heart!"
        },
        {
          "name": "Bohol Danao Adventure Park",
          "source": require("../assets/tour_photos/Danao_Adventure _Park.jpg"),
          "info": "Embark on a fun-filled tour to Bohol’s Bohol Danao Adventure Park and try the exciting activities that await you in the middle of green valleys and hills. This trip is perfect for thrill-seekers who want to go on an eco-adventure and experience a different side of Bohol. the Bohol Danao Adventure Park offers a range of high-adrenaline activities that adventurous travelers would love doing. Sky riding, cliff walking, rappelling, mountain climbing, caving, river trekking, and spelunking among others. "
        },
      ]
  },
  {
      "packageName": "Bohol Abatan Firefly Tours",
      "tours": [
          {
              "tourName": "Bohol Abatan Firefly Watching",
              "inclusions": [
                  "Pickup from Hotel or Resort  6:00  PM",
                  "Proceed to Bohol Abatan Firefly Watching",
                  "Estemated End of the Tour 8:30 PM",
                  "Boat Fees Included"
              ],
              "durations": "2-3 hours",
              "prices": [
                  {
                    "vehicle": "VAN",
                    "pax": "2",
                    "ratePerPerson": 5500,
                  },
                  {
                    "vehicle": "VAN",
                    "pax": "3",
                    "ratePerPerson": 4533
                  },
                  {
                    "vehicle": "VAN",
                    "pax": "4",
                    "ratePerPerson": 3500
                  },
                  {
                    "vehicle": "VAN",
                    "pax": "5",
                    "ratePerPerson": 2850
                  },
                  {
                    "vehicle": "VAN",
                    "pax": "6",
                    "ratePerPerson": 2371
                  },
                  {
                    "vehicle": "BOAT",
                    "pax": "7",
                    "ratePerPerson": 2050
                  },
                  {
                    "vehicle": "BOAT",
                    "pax": "8",
                    "ratePerPerson": 1819
                  },
                  {
                    "vehicle": "BOAT",
                    "pax": "9",
                    "ratePerPerson": 1666
                  },
                  {
                    "vehicle": "BOAT",
                    "pax": "10",
                    "ratePerPerson": 1555
                  },
                  {
                    "vehicle": "BOAT",
                    "pax": "11",
                    "ratePerPerson": 1482
                  },
                  {
                    "vehicle": "BOAT",
                    "pax": "12",
                    "ratePerPerson": 1417
                  },
                ],
              "image": {
                "name": "Firefly Watching",
                "source": require("../assets/tour_photos/Firefly.jpg"),
                "info": "Hop on a boat and watch fireflies twinkle in the trees along Abatan River in Bohol with this firefly watching night cruise tour. If you’re looking for a relaxing activity to cap off your day in the province, then this early evening escapade is definitely worth booking.Listen to your guide as they will tell you about the species of fireflies in Bohol and the mangroves that they call their home in the Abatan River. Your guide will also point out the trees full of fireflies, so be sure to listen and be on the lookout. The boat will stop for a few minutes near trees filled with fireflies so you can take everything in. Don’t forget to also look up at the night sky, as the darkness will help you see more stars. Fill your night with a magical firefly show with this firefly watching cruise in the Abatan River in Bohol."
              }
                
          }
      ],
      "details": "Rent Van with Licensed & Polite tour Driver Guide/Photographer. Pick-up and drop-off Tagbilaran or Panglao accommodation or Sea/Air-Port (look for driver bring sign with your name in Arrival Area Outside). Parking fee included in Package. Fully Air conditioned Vehicle transportation and gasoline. Bohol Bohol Abatan Firefly Watching tour package that lets you visit the tourist spots at Abatan.  (scroll down for more information)",
      "photos": [
        {
          "name": "Firefly Watching",
          "source": require("../assets/tour_photos/Firefly.jpg"),
          "info": "Hop on a boat and watch fireflies twinkle in the trees along Abatan River in Bohol with this firefly watching night cruise tour. If you’re looking for a relaxing activity to cap off your day in the province, then this early evening escapade is definitely worth booking.Listen to your guide as they will tell you about the species of fireflies in Bohol and the mangroves that they call their home in the Abatan River. Your guide will also point out the trees full of fireflies, so be sure to listen and be on the lookout. The boat will stop for a few minutes near trees filled with fireflies so you can take everything in. Don’t forget to also look up at the night sky, as the darkness will help you see more stars. Fill your night with a magical firefly show with this firefly watching cruise in the Abatan River in Bohol."
        }
      ]
  }
]

export const addOns = [
  {
      "addOnName": "ATV Adventures",
      "addOnPrice": "1,200 / hour",
  },
  { 
      "addOnName": "Loboc eco-park (cable car and zip line) ",
      "addOnPrice": " 450 / pax"
  }
]

export const tourData = [
  {
    "tourName": "Bohol Countryside Tour A",
    "inclusions": [
        "Loboc/Loay river cruise Buffet Lunch included",
        "Blood Compact Shine",
        "Chocolate Hills and Obsevatory Deck",
        "Butterfly Conservatory",
        "Sevilla Hanging Bridge",
        "Bilar Man-Made Forest",
        "Baclayon Church",
        "Souvernier Shops",
        "Entrance and parking fees",
        "Department of Tourism accredited aircon transportation",
        "Hotel pick-up/drop-off",
        "Tour driver will serve as a local tourist guide",
        "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
    ],
    "durations": "8 hours duration",
    "prices": [
      {
        "pax": 2,
        "ratePerPerson": 6000,
      },
      {
        "pax": 3,
        "ratePerPerson": 4385,
      },
      {
        "pax": 4,
        "ratePerPerson": 3550,
      },
      {
        "pax": 5,
        "ratePerPerson": 3150,
      },
      {
        "pax": 6,
        "ratePerPerson": 3135,
      },
      {
        "pax": 7,
        "ratePerPerson": 2907,
      },
      {
        "pax": 8,
        "ratePerPerson": 2700,
      },
      {
        "pax": 9,
        "ratePerPerson": 2500,
      },
      {
        "pax": 10,
        "ratePerPerson": 2450,
      },
      {
        "pax": 11,
        "ratePerPerson": 2420,
      },
      {
        "pax": 12,
        "ratePerPerson": 2400,
      }
    ],
    "image":
      {
        "name": "Tarsier Sanctuary",
        "source": require("../assets/tour_photos/Tarsier_Sanctuary.jpg"),
        "info": "The tarsier is considered as the world's smallest primate. An endangered species, the tarsier is a tree-climbing and insect-eating primate with large eyes."
      },       
  },
  {
    "tourName": "Bohol Countryside Tour B",
    "inclusions": [
        "Blood Compact Shine",
        "Chocolate Hills and Obsevatory Deck",
        "Butterfly Conservatory",
        "Sevilla Hanging Bridge",
        "Bilar Man-Made Forest",
        "Baclayon Church",
        "Souvernier Shops",
        "Entrance and parking fees",
        "Department of Tourism accredited aircon transportation2",
        "Hotel pick-up/drop-off",
        "Tour driver will serve as a local tourist guide",
        "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
    ],
    "durations": "8 hours duration",
    "prices": [
      {
        "pax": 2,
        "ratePerPerson": 5200
      },
      {
        "pax": 3,
        "ratePerPerson": 3534
      },
      {
        "pax": 4,
        "ratePerPerson": 2700
      },
      {
        "pax": 5,
        "ratePerPerson": 2300
      },
      {
        "pax": 6,
        "ratePerPerson": 2034
      },
      {
        "pax": 7,
        "ratePerPerson": 1914
      },
      {
        "pax": 8,
        "ratePerPerson": 1763
      },
      {
        "pax": 9,
        "ratePerPerson": 1645
      },
      {
        "pax": 10,
        "ratePerPerson": 1550
      },
      {
        "pax": 11,
        "ratePerPerson": 1473
      },
      {
        "pax": 12,
        "ratePerPerson": 1410
      }
    ],
    "image":{
      "name": "Chocolate Hills",
      "source": require("../assets/tour_photos/Chocolate_Hills.jpg"),
      "info": "The jewel of Bohol tourism, the Chocolate Hills is considered as one of the world's famous natural wonders. Thousands of brown and green hills are spread out all over the place as far as your eyes can see. Definitely a must-visit for every tourist and traveller to Bohol. The Chocolate Hills are a group of unusually shaped hills located in the middle of the island of Bohol in Philippines. This extraordinary landscape is unique to this small island."
    }
  },
  {
    "tourName": "Bohol Countryside Tour C",
    "inclusions": [
        "Sikatunas Mirror of The World",
        "Loboc/Loay river cruise with Buffe Lunch",
        "Chocolate Hills and Observatory Deck",
        "Butterfly Conservatory",
        "Bilar Man-Made Forrest",
        "Sevilla Hanging Bridge",
        "Baclayon Church",
        "Blood Compact Shrine",
        "Souvernir Shops",
        "Entrance and parking fees",
        "Department of Tourism accredited aircon transportation",
        "Hotel pick-up/drop-off",
        "Tour driver will serve as a local tourist guide",
        "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
    ],
    "durations": "8 hours and 30 minutes duration",
    "prices": [
        {
          "pax": 2,
          "ratePerPerson": 6250
        },
        {
          "pax": 3,
          "ratePerPerson": 4617
        },
        {
          "pax": 4,
          "ratePerPerson": 3800
        },
        {
          "pax": 5,
          "ratePerPerson": 3410
        },
        {
          "pax": 6,
          "ratePerPerson": 3066
        },
        {
          "pax": 7,
          "ratePerPerson": 2821
        },
        {
          "pax": 8,
          "ratePerPerson": 2625
        },
        {
          "pax": 9,
          "ratePerPerson": 2584
        },
        {
          "pax": 10,
          "ratePerPerson": 2550
        },
        {
          "pax": 11,
          "ratePerPerson": 2550
        },
        {
          "pax": 12,
          "ratePerPerson": 2550
        }
      ],
    "image": { 
      "name": "Blood Compact Shrine",
      "source": require("../assets/tour_photos/Blood_Compact_Shrine.jpg"),
      "info": "The Blood Compact Monument is an artwork that was made to honor the first international treaty between the Philippines and Spain. The memorial stands in an area along the side of what is now known as Tagbilaran East Road in the Bool District. Its current spot holds historical significance since it is where  Miguel Lopez de Legaspi and Rajah Sikatuna performed a blood compact on March 16, 1565. This gesture supposedly signified peace and friendship between the foreigners and the natives of Bohol."
    }
  },
  {
  "tourName": "Bohol Panglao Tour A",
  "inclusions": [
      "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
      "Panglao Church and Watch Tower",
      "Seashell Museum",
      "Hinagdanan Cave",
      "Bohol Bee Farm",
      "Dauis Church and Miracle Well",
      "Moadto Strip Mall",
      "Side Trip to Mist Café & restaurant / La Vara or Molly Wa / Molly Bistro",
      "Alona Beach",
      "2:30 PM Estemated time End of the Tour. Drop off to Hotel / Resort"
  ],
  "durations": "5-6 hours",
  "prices": [
    {
      "pax": 2,
      "ratePerPerson": 4250
    },
    {
      "pax": 3,
      "ratePerPerson": 2916
    },
    {
      "pax": 4,
      "ratePerPerson": 2250
    },
    {
      "pax": 5,
      "ratePerPerson": 2150
    },
    {
      "pax": 6,
      "ratePerPerson": 1833
    },
    {
      "pax": 7,
      "ratePerPerson": 1607
    },
    {
      "pax": 8,
      "ratePerPerson": 1438
    },
    {
      "pax": 9,
      "ratePerPerson": 1305
    },
    {
      "pax": 10,
      "ratePerPerson": 1200
    },
    {
      "pax": 11,
      "ratePerPerson": 1159
    },
    {
      "pax": 12,
      "ratePerPerson": 1083
    }
  ],
  "image": {
    "name": "Alona Beach",
    "source": require("../assets/tour_photos/Alona_Beach.jpg"),
    "info": "Welcome to Alona Beach!Alona Beach is a small stretch of tropical paradise on Panglao Island, Bohol in the Philippines. It is quickly becoming one of the top travel destinations in the Philippines because of it's wonderful white sand beach, beautiful blue waters, world class diving and tours."

    }
  },
  {
  "tourName": "Bohol Panglao Tour B",
  "inclusions": [
    "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
      "Sikatuna Mirror Of The World",
      "Dauis Church",
      "St.Joseph Cathedral",
      "Bird feeding @ Tagbilaran plaza",
      "Hinagdanan Cave",
      "Bohol Bee Farm",
      "Swimming at Dumaluan Beach (Upon request, add P 100 per person)",
      "Panglao Church and Watch Tower",
      "Mist Restaurant and Cafe",
      "Modala Strip Mall",
      "2:30 PM Estemated time End of the Tour. Drop off to Hotel / Resort"
  ],
  "durations": "5-6 hours",
  "prices": [
    {
      "pax": 2,
      "ratePerPerson": 4650
    },
    {
      "pax": 3,
      "ratePerPerson": 3267
    },
    {
      "pax": 4,
      "ratePerPerson": 2575
    },
    {
      "pax": 5,
      "ratePerPerson": 2200
    },
    {
      "pax": 6,
      "ratePerPerson": 1983
    },
    {
      "pax": 7,
      "ratePerPerson": 1771
    },
    {
      "pax": 8,
      "ratePerPerson": 1613
    },
    {
      "pax": 9,
      "ratePerPerson": 1489
    },
    {
      "pax": 10,
      "ratePerPerson": 1390
    },
    {
      "pax": 11,
      "ratePerPerson": 1309
    },
    {
      "pax": 12,
      "ratePerPerson": 1200
    }
  ],
  "image": {
    "name": "Dauis Church",
    "source": require("../assets/tour_photos/Dauis_Church.jpg"),
    "info": "The church in Dauis is one of the sophisticated and complex structures of its kind in Bohol. Built according to a plan for basilicas, that is, with a high central nave flanked by lower aisles, certain innovations have been added such as a pair of extensions to form “transepts “. The transept “arms” are unique, as their walls are built diagonal to the nave, instead of the common right-angle configuration. The crossing is topped by a cupola in the shape of a low octagonal pyramid."

    }
  },
  {
  "tourName": "Bohol Panglao Tour C",
  "inclusions": [
      "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
      "Hinagdanan Cave",
      "Dauis Church",
      "Alona Beach",
      "Bee Farm",
      "Floating Restaurant Loboc/Loay river cruise Buffet Lunch included",
      "2:30 PM Estemated time End of the Tour. Drop off to Hotel / Resort"
  ],
  "durations": "Not Specified",
  "prices": [
    {
      "pax": 2,
      "ratePerPerson": 5050
    },
    {
      "pax": 3,
      "ratePerPerson": 3783
    },
    {
      "pax": 4,
      "ratePerPerson": 3150
    },
    {
      "pax": 5,
      "ratePerPerson": 2770
    },
    {
      "pax": 6,
      "ratePerPerson": 2516
    },
    {
      "pax": 7,
      "ratePerPerson": 2357
    },
    {
      "pax": 8,
      "ratePerPerson": 2200
    },
    {
      "pax": 9,
      "ratePerPerson": 2094
    },
    {
      "pax": 10,
      "ratePerPerson": 2010
    },
    {
      "pax": 11,
      "ratePerPerson": 1941
    },
    {
      "pax": 12,
      "ratePerPerson": 1883
    }
  ],
  "image": {
    "name": "Hinagdanan Cave",
    "source": require("../assets/tour_photos/Hinagdanan_Cave.jpg"),
    "info": "One of the most renowned caves in Bohol is the Hinagdanan Cave. Looking around the cave one can see stalactites hanging from the ceiling and protruding stalagmites on the ground. The cave has two holes aboveground gives a streak of light towards the upper left side of the cave. Hinagdanan is a karst cave which means it is made of limestones. Boasting several stalactite and stalagmite formations parallel to each other and a fresh water lagoon that is about 10 to 12 meters deep. "

    }
  },
  {
    "tourName": "Bohol Panglao Tour D",
    "inclusions": [
        "Hinagdaan Cave Dauis",
        "Panglao Beach",
        "Alona Beach",
        "Panglao Island Hopping",
        "Bohol Bee Farm",
        "All Entrance fees",
        "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport"
    ],
    "durations": "5-6 hours",
    "prices": [
      {
        "pax": 2,
        "ratePerPerson": 6000
      },
      {
        "pax": 3,
        "ratePerPerson": 4666
      },
      {
        "pax": 4,
        "ratePerPerson": 3875
      },
      {
        "pax": 5,
        "ratePerPerson": 3400
      },
      {
        "pax": 6,
        "ratePerPerson": 3084
      },
      {
        "pax": 7,
        "ratePerPerson": 2857
      },
      {
        "pax": 8,
        "ratePerPerson": 2687
      },
      {
        "pax": 9,
        "ratePerPerson": 2556
      },
      {
        "pax": 10,
        "ratePerPerson": 2450
      },
      {
        "pax": 11,
        "ratePerPerson": 2364
      },
      {
        "pax": 12,
        "ratePerPerson": 2250
      }
    ],
    "image": {
      "name": "Alona Beach",
      "source": require("../assets/tour_photos/Alona_Beach.jpg"),
      "info": "Welcome to Alona Beach!Alona Beach is a small stretch of tropical paradise on Panglao Island, Bohol in the Philippines. It is quickly becoming one of the top travel destinations in the Philippines because of it's wonderful white sand beach, beautiful blue waters, world class diving and tours."
  
      }
    },
  {
    "tourName": "Bohol Anda Tour A",
    "inclusions": [
      "Pickup go to Jagna Mayana Strawberry Farm 5:30 AM",
      "Arrival Mayana Strawberry Farm 7:15",
      "Departure to Can-Umatad Rice Terraces in Candijay 8:30",
      "Arrivel Candijay Rice Terraces 10:00",
      "Procced to Can-Umantad Falls 11:00",
      "Arrival Can-Umatad Falls 11:30",
      "Departure  Going to Anda White Beach 12:30 PM",
      "The estimated arrival time at Anda Quinale Beach is 1:30 pm.  There will be lunch available for purchase at your own expense.",
      "Go to Anda Cave pool 3:00",
      "Departure going back to Resort Hotel 5:00",
      "End of Tour and Arrival Resort Hotel 7:30 pm.",
  ],
  "durations": "10-12 hours",
  "prices": [
    {
      "pax": 2,
      "ratePerPerson": 7000
    },
    {
      "pax": 3,
      "ratePerPerson": 5000
    },
    {
      "pax": 4,
      "ratePerPerson": 4167
    },
    {
      "pax": 5,
      "ratePerPerson": 3400
    },
    {
      "pax": 6,
      "ratePerPerson": 3000
    },
    {
      "pax": 7,
      "ratePerPerson": 2714
    },
    {
      "pax": 8,
      "ratePerPerson": 2500
    },
    {
      "pax": 9,
      "ratePerPerson": 2334
    },
    {
      "pax": 10,
      "ratePerPerson": 2200
    },
    {
      "pax": 11,
      "ratePerPerson": 2090
    },
    {
      "pax": 12,
      "ratePerPerson": 2000
    }
  ],
    "image": {
      "name": "Strawberry Farm Mayana",
      "source": require("../assets/tour_photos/Strawberry_Farm.jpg"),
      "info": "Strawberry Farm Mayana the Bohol Organic and Strawberry Farm in Brgy. Mayana, Jagna in the province of Bohol offers a wide area of sweet and succulent strawberries available for picking. It is believed that growing of strawberries in the elevated Brgy. Mayana is probably ideal because of its cool temperature.  It is advisable to visit the place early in the morning for higher chances of picking more strawberries. Not just that, there are times when the place is cloaked with cold fog so you might want to experience that. Aside from strawberries, you can also see other organic vegetables like lettuce, tomatoes, pepper and a lot more. Note that you have to pay for what you have harvested. While here, guests are treated with the grand view of the distant verdant hills. Bohol’s highest peak is visible from Bohol Organic and Strawberry Farm. Who would have thought that picking strawberries can be this relaxing? Make sure to bring your family and friends for a good quality time. "
    }  
  },
  {
    "tourName": "Bohol Anda Tour B",
    "inclusions": [
      "Pick-up go to Can-Umatad Rice Terraces in Candijay 6:00 am",
      "Arrival Candijay Riceterraces 9:00 am",
      "Proceed to Can-Umatad Falls 9:30 am",
      "Swimming and take pictures at the Falls 10:00 am",
      "Departure going to Anda White Beach 12:00 pm",
      "Arrival in Anda Quinale White Beach Eat lunch  and Enjoy swimming 1:00 PM",
      "Go to Anda Cave Pool 3:00",
      "Departure going Back to Resort 5:00 pm",
      "Estimated end of Tour and Arrival Resort 8:00 pm (you may drop at Jagna town if  you Want buy Calamay)."
  ],
  "durations": "10-12 hours",
  "prices": [
      {
        "pax": 2,
        "ratePerPerson": 5500
      },
      {
        "pax": 3,
        "ratePerPerson": 4000
      },
      {
        "pax": 4,
        "ratePerPerson": 3250
      },
      {
        "pax": 5,
        "ratePerPerson": 2800
      },
      {
        "pax": 6,
        "ratePerPerson": 2500
      },
      {
        "pax": 7,
        "ratePerPerson": 2285
      },
      {
        "pax": 8,
        "ratePerPerson": 2125
      },
      {
        "pax": 9,
        "ratePerPerson": 2000
      },
      {
        "pax": 10,
        "ratePerPerson": 1900
      },
      {
        "pax": 11,
        "ratePerPerson": 1818
      },
      {
        "pax": 12,
        "ratePerPerson": 1750
      }
    ],
    "image": {
      "name": "Can-Umantad Falls",
      "source": require("../assets/tour_photos/Can_Umatad_Falls.jpg"),
      "info": "Can-Umantad Falls in Candijay is one of the most impressive waterfalls you will find on the incredible island of Bohol, Philippines! There are also two other great attractions to visit in Candijay, which will make it a great trip. Stop off to see the Cadapdapan Rice Terraces and cool off at Canawa Cold Spring afterward."
    }
      
  },
  {
    "tourName": "Bohol Danao Adventure Park",
    "inclusions": [
        "Pickup services: From airport/seaport to hotel. From hotel to airport/seaport",
        "ETA Sea of Clouds Area 5:30",
        "Departure go to Bohol Danao Adventure Park 7:00",
        "ETA Danao Eco-Park 9:00 ",
        "Arrival the Danao Extreme Adventure Park 9:30 (estemated time at Park 7 hours).",
        "Departure going Back to the Resort 4:30 PM",
        "Estemated end of the Tour 6:30"
    ],
    "durations": "Up to 10 hours",
    "prices": [
        {
          "pax": 2,
          "ratePerPerson": 5100
        },
        {
          "pax": 3,
          "ratePerPerson": 3600
        },
        {
          "pax": 4,
          "ratePerPerson": 2700
        },
        {
          "pax": 5,
          "ratePerPerson": 2260
        },
        {
          "pax": 6,
          "ratePerPerson": 1933
        },
        {
          "pax": 7,
          "ratePerPerson": 1686
        },
        {
          "pax": 8,
          "ratePerPerson": 1525
        },
        {
          "pax": 9,
          "ratePerPerson": 1388
        },
        {
          "pax": 10,
          "ratePerPerson": 1290
        },
        {
          "pax": 11,
          "ratePerPerson": 1218
        },
        {
          "pax": 12,
          "ratePerPerson": 1166
        }
      ],
    "image": {
      "name": "Sea of Clouds",
      "source": require("../assets/tour_photos/Sea_Of_Clouds.jpg"),
      "info": "Up in the mountains of Danao in central Bohol there is a viewpoint aptly known as Bohol Sea of Clouds, or Mt. Puntaas. With a viewing area over rice terraces tumbling down into the clouds and an epic ride along Bohol’s countryside sprawling with nature, viewpoints won’t get better than this!"
    }
  },
  {
    "tourName": "Bohol Abatan Firefly Watching",
    "inclusions": [
        "Pickup from Hotel or Resort  6:00  PM",
        "Proceed to Bohol Abatan Firefly Watching",
        "Estemated End of the Tour 8:30 PM",
        "Boat Fees Included"
    ],
    "durations": "2-3 hours",
    "prices": [
      {
        "vehicle": "VAN",
        "pax": "2",
        "ratePerPerson": 5500,
      },
      {
        "vehicle": "VAN",
        "pax": "3",
        "ratePerPerson": 4533
      },
      {
        "vehicle": "VAN",
        "pax": "4",
        "ratePerPerson": 3500
      },
      {
        "vehicle": "VAN",
        "pax": "5",
        "ratePerPerson": 2850
      },
      {
        "vehicle": "VAN",
        "pax": "6",
        "ratePerPerson": 2371
      },
      {
        "vehicle": "BOAT",
        "pax": "7",
        "ratePerPerson": 2050
      },
      {
        "vehicle": "BOAT",
        "pax": "8",
        "ratePerPerson": 1819
      },
      {
        "vehicle": "BOAT",
        "pax": "9",
        "ratePerPerson": 1666
      },
      {
        "vehicle": "BOAT",
        "pax": "10",
        "ratePerPerson": 1555
      },
      {
        "vehicle": "BOAT",
        "pax": "11",
        "ratePerPerson": 1482
      },
      {
        "vehicle": "BOAT",
        "pax": "12",
        "ratePerPerson": 1417
      },
    ],
    "image": {
      "name": "Firefly Watching",
      "source": require("../assets/tour_photos/Firefly.jpg"),
      "info": "Hop on a boat and watch fireflies twinkle in the trees along Abatan River in Bohol with this firefly watching night cruise tour. If you’re looking for a relaxing activity to cap off your day in the province, then this early evening escapade is definitely worth booking.Listen to your guide as they will tell you about the species of fireflies in Bohol and the mangroves that they call their home in the Abatan River. Your guide will also point out the trees full of fireflies, so be sure to listen and be on the lookout. The boat will stop for a few minutes near trees filled with fireflies so you can take everything in. Don’t forget to also look up at the night sky, as the darkness will help you see more stars. Fill your night with a magical firefly show with this firefly watching cruise in the Abatan River in Bohol."
    }
      
  }
]