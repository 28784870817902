import styled from 'styled-components';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import { device, text } from '../../../utilities/sizes.util';

export const SliderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 180px;
        width: 340px;
        max-width: 100%;
        max-height: 100%; 
        border-radius: 8px 
    }
`;
