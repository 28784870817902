import React, { useState } from 'react';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import SwipeableViews from 'react-swipeable-views';
import { SliderContainer, NextButton, PrevButton } from './mobile-slider.styles';


export const MobileSliderComponent = ({ data, children }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { currentScreen } = useState(ResponsiveScreenContext)
  const nextSlide = () => {
    setCurrentSlide((currentSlide + 1) % data.length);
  };

  const prevSlide = () => {
    setCurrentSlide((currentSlide - 1 + data.length) % data.length);
  };


  const handleChangeIndex = newIndex => {
    setCurrentSlide(newIndex);
  };

  const handleSwitch = (newIndex, type) => {
    if (type === 'end') {
      setCurrentSlide(newIndex);
    }
  };

    return (
      <SliderContainer className={currentScreen}>
          <NextButton onClick={prevSlide} />
          <SwipeableViews
            className='swiper'
            index={currentSlide}
            onSwitching={handleSwitch}
            onChangeIndex={handleChangeIndex}
           >
            {children}
            </SwipeableViews>
          <PrevButton onClick={nextSlide} />
      </SliderContainer>
    );
};