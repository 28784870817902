import React, { useContext, useState, useEffect } from 'react';
import {FormContext} from '../../contexts/form.context';
import { IsContext } from '../../contexts/is.context';
import {PaymentContainer, FormChildrenContainer, Form, AddonContainer, SubmitContainer, ButtonContainer } from './tour.checkout.styles';
import { Button, BUTTON_TYPE_CLASSES } from '../button/button.component';
import { SeparatorComponent, SeparatorWithTextComponent } from '../separator/broken-line.separator.component';
import { createPaymentIntent } from '../../utilities/firebase.util';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { forSendingConfirmationEmail } from '../../utilities/firebase.util';

const FormFieldsComponent = ({ formFields, button, onClick }) => {
  const { fullName, 
    email, 
    contactNumber, 
    numberOfGuest, 
    desiredDate, 
    pickupLocation,
    additionalInformation,

   } = formFields

  return (
    <FormChildrenContainer>
      <div>
        <p >
           <span>Full Name</span>: {fullName}
       </p>
       <p >
           <span>Email</span>: {email}
       </p>
       <p >
           <span>Contact Number</span>: {contactNumber}
       </p>
       <p >
           <span>Number of Guests</span>: {numberOfGuest}
       </p>
       <p >
           <span>Desired Date</span>: {desiredDate}
       </p>
       <p >
           <span>Pickup Location</span>: {pickupLocation}
       </p>
       <p >
           <span>Addition Information</span>: {additionalInformation}
       </p>
  </div>
      {button && (
        <ButtonContainer>
          <Button type='button'  onClick={onClick} buttonType={BUTTON_TYPE_CLASSES.base}>
            Edit
          </Button>
        </ButtonContainer>
      )}
    </FormChildrenContainer>
  );
};

const OrderInformationComponent = ({ orderDetails }) => {
  const { 
    tourName,
    numberOfGuests,
    ratePerPerson,
    total
   } = orderDetails.packages;

   let person = 'person'
   if (numberOfGuests > 1) {
    person = 'persons'
   };

  return (
    <FormChildrenContainer>
      <p >
           <span>{tourName}</span> 
       </p>
       <p >
          For {numberOfGuests} {person } the rate per head/pax is {ratePerPerson} PHP
       </p>
       <div className='cost-to-pay'>
          <div className='subtotal'>
            Subtotal:
          </div>
          <div className='amount'>
            <p>
              {numberOfGuests} x {ratePerPerson} = <span>{total}</span>
            </p>
          </div>
       </div>
    </FormChildrenContainer>
    
  );
};

const AddOnFieldsComponent = ({addon, orderDetails, setOrderDetails}) => {
  const {addonName, addonRate, addonPrice} = addon
  const [value, setValue] = useState(0);

  useEffect(() => {
    const newValue = parseInt(value);
    
    if (isNaN(newValue)) {
      const timer = setTimeout(() => {
        setValue(0);
      }, 30000); // = 30 seconds

      return () => clearTimeout(timer);
    } else if (newValue === 0) {
      // Remove the addon from orderDetails if the new value is 0
      const updatedAddons = orderDetails.addons.filter(addon => addon.addonName !== addonName);
      setOrderDetails(prevFields => ({
        ...prevFields,
        addons: updatedAddons
      }));
    } else {

      // Update the quantity if the addon already exists, or add a new addon
      const existingAddon = orderDetails.addons.find(addon => addon.addonName === addonName);

      if (existingAddon) {
        const updatedAddons = orderDetails.addons.map(addon =>
          addon.addonName === addonName ? { ...addon, quantity: newValue, total: newValue * addonPrice } : addon
        );

        setOrderDetails(prevFields => ({
          ...prevFields,
          addons: updatedAddons
        }));
      } else {
        const newAddon = {
          addonName,
          addonRate,
          addonPrice,
          quantity: newValue,
          total: newValue * addonPrice
        };

        setOrderDetails(prevFields => ({
          ...prevFields,
          addons: [...prevFields.addons, newAddon]
        }));
      }
    }
  }, [value]);

  const calculateAddonTotalAmount = () => {
    return orderDetails.addons.reduce((total, addon) => total + addon.total, 0);
  };

  useEffect(() => {
    const newTotalAmount = orderDetails.packages.total + calculateAddonTotalAmount();

    setOrderDetails(prevFields => ({
      ...prevFields,
      totalAmount: newTotalAmount // Update the totalAmount in orderDetails
    }));
  }, [orderDetails.addons]);


  const handleIncrement = () => {
    setValue(value + 1);
  };

  const handleDecrement = () => {
    if (value > 0) {
      setValue(value - 1)
    };
  };

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value);
    setValue(isNaN(newValue) ? 0 : newValue);
  };

  return (
    <FormChildrenContainer className='orderInfoContainer'>
      <div className='order-info'>
        {(value > 0 || value === '') && <button type='button' className='minus'  onClick={handleDecrement}>-</button>}
        {(value > 0  || value === '')  && <input type='text' value={value} onChange={handleChange} /> }
        <button  type='button' className='plus'  onClick={handleIncrement}>+</button>
        <label className='label-addon' htmlFor={addonName}>{addonName} ({addonRate})</label>
      </div>
      {(value > 0 || value === '') &&
        <div className='addon-info-price'>
            {value} x {addonPrice} = <span> { value * addonPrice}</span>
        </div>
      }
    </FormChildrenContainer>
      
  )
};


export const CheckoutFormComponent = ({selectedPackage}) => {
  const stripe = useStripe();
  const elements = useElements();
  const {
    formFields,
    orderDetails,
    setOrderDetails,
  } = useContext(FormContext);
  const { setIsCheckoutForm } = useContext(IsContext);
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    
    const amount = orderDetails.totalAmount * 100
    const promiseResult = await createPaymentIntent(amount)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error('Error:', error);
      throw error;
    });

    const clientSecret = promiseResult.data.clientSecret;

    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: formFields.fullName,
        },
      },
    });

    if (paymentResult.error) {
      alert(paymentResult.error.message);
    } else {
      if (paymentResult.paymentIntent.status === 'succeeded') {
        alert('Payment Successful!');
        console.log(formFields)
        console.log(orderDetails);

        const data = {
          providedInformation: formFields,
          orderDetails: orderDetails,
        }
    
        forSendingConfirmationEmail(data)
        .then((result) => {
          // Handle the result from your Firebase Function
          console.log('Function result:', result.data);
          console.log(orderDetails);
        })
        .catch((error) => {
          // Handle any errors that occur
          console.error('Function error:', error);
        });
      }
    }
  };

  const handleEdit = () => {
    setIsCheckoutForm(false);
  }
  

  return (
      <Form onSubmit={handleSubmit}>
          <div className='booking-form-header'>
            <SeparatorComponent text={
            <h2>
                Checkout
            </h2>
          }/>
          </div>
      <SeparatorWithTextComponent text={<h3 style={{textAlign:'center'}}>Submitted Information</h3>}/>
        <FormFieldsComponent 
          header={'Submitted Information:'}
          formFields={formFields}
          button
          onClick={handleEdit}
        />
        <SeparatorWithTextComponent text={<h3 style={{textAlign:'center'}}>Order Information</h3>}/>
        <OrderInformationComponent 
          orderDetails={orderDetails}
        />
        {selectedPackage.addons &&
          <div className='addonTitle' >
              <label htmlFor='addOns'>Addons</label>
          </div>
        }
      {selectedPackage.addons && 
        selectedPackage.addons.map((addon, index) => (
        <AddonContainer key={`checkbox-${index}`}>
              <AddOnFieldsComponent 
                key={`addon-${index}`}
                addon={addon}
                orderDetails={orderDetails}
                setOrderDetails={setOrderDetails}
                />
        </AddonContainer>
        ))}
        <hr style={{height: '20px', border: 'none'}} />
        <SeparatorWithTextComponent/>
          <div className='total-amount'>
            <div className='total'>Total: </div>
            <div className='amount'>{orderDetails.totalAmount} PHP</div>
          </div>
        <SeparatorWithTextComponent/>
          <PaymentContainer>
            <CardElement
            options={{
                style: {
                  base: {
                    iconColor: 'black',
                    fontFamily: 'Consolas, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "20px",
                    "::placeholder": {
                      iconColor: 'black',
                      color: "black",
                    },
                  },
                  invalid: {
                    color: "#fa755a",
                    iconColor: "#fa755a"
                  }
                }
            }}
          />
          <ButtonContainer>
              <Button id='checkout-button' buttonType={BUTTON_TYPE_CLASSES.inverted}>
                          Pay
              </Button>
          </ButtonContainer>

          </PaymentContainer>
        </Form>
  )
}

