import styled from 'styled-components';


export const PersonalContainer = styled.section`
    display: flex;
    flex-direction: column;
`;

export const Image = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    gap: 40px;
    padding: 0 20px;
    width: 100%;
    height: 100%;

    img {
        min-width: 334px;
        height: 324px;
    }

    &.column-personals {
        flex-direction: column;
    }
`;
