import styled from 'styled-components';
import { text } from '../../utilities/sizes.util';

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 8px;
    border: 2px solid #DDD;
    width: 100%;
    padding: 15px 0 15px 0;

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
        margin-bottom: 5px;
    }
    h3 {
        margin: 0;
        padding-bottom: 15px;
        text-align: center;
    }

    button {
        display: none;
    }

    &:hover {
        img {
            opacity: 0.8;
        }
        
        button {
            width: 80%;
            opacity: 0.7;
            position: absolute;
            top: 45%;
            opacity: 3;
            display: flex;
        }
    }


    &.mobile, &.smobile {
        button {
            display: flex;
            width: 80%;
        }

        &:hover {
            button {
                display: flex;
                position: relative;
            }
        }
    }
`;

export const Header = styled.div`
    display: flex;
`;

export const Hero = styled.div`
    display: flex;
`;

export const Footer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h5 {
        padding: 0 0 10px 0;
        margin: 0;
        text-align: center;
        text-decoration: underline;
    }

    p {
        padding: 10px 20px;
        margin: 0;
        text-align: center;
    }
`;
