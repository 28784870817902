import styled from 'styled-components';
import { text } from '../../../utilities/sizes.util';

export const StyledUL = styled.ul`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 5px;
  
  li {
    padding: 5px;
    font-size: ${text.list} 
  }

`;