import React, { createContext, useState } from 'react';

export const RoutNameContext = createContext({
    routeName: '',
    setRouteName: () => {},
  });
  
  export const RouteNameProvider = ({ children }) => {
    const [routeName, setRouteName] = useState('');

    const value = {
        routeName,
        setRouteName,
      };

      return (
        <RoutNameContext.Provider value={value}>
            {children}
        </RoutNameContext.Provider>
      )
    };