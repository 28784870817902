import styled from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';
import { icon } from '../../utilities/sizes.util';

export const TourListNavContainer = styled.nav`
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    border-bottom: 2px solid rgba(1,1,1,0.20);
    position: fixed;
    background-color: white;
    z-index: 964;
    width: 100%;


    li {
        text-align: center;
        white-space: nowrap;
        opacity: 0.9;
    }

    span {
        color: rgba(1,1,1,0.40);
        padding: 0 10px;
        height: 100%;
    }


    .list-group {
        display: flex;
        align-items: center;
        padding: 10px 20px;
    }

    .list {
        display: flex;
        align-items: center; /* Vertically center items */
        border-bottom: 2px solid rgba(1,1,1,0.20);
        padding: 10px 20px;
        color: blue;

        .arrow {
            display: flex;
            align-items: center;
            padding: 0 10px;
        }

        .list-name {
            display: flex;
            align-items: center;
            
        }
    }

`;

export const ArrowLeft = styled(FaArrowLeft)`
    font-size: ${icon.medium};
    

`;

export const TimeNavComponentContainer = styled.nav`
    
`;