import styled from 'styled-components';
import { text } from '../../utilities/sizes.util';

const TableComponentContainer = styled.section`
  display: flex;

`;

const Table = styled.table`
  border-collapse: collapse;
  max-height: 800px;
`;

const THead = styled.thead`
  /*background-color: #eeeeee;*/
  color: black;
  text-align: left;
`;

const TH = styled.th`
  text-align: left;
  padding: 8px 8px;
  height: 80px;
`;

const TR = styled.tr`
`;

const TD = styled.td`
  padding-left: 8px;

`;

const InclutionTableContainer = styled.div`
  display: flex
`;

const RatePerPaxAndTotalContainer = styled.div`
  display: flex
`;

const TableComponentDesktopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ul {
    padding-left: 20px;
  }

  h4 {
    padding-top: 20px;
  }
  
  .table-pax-rate-total {
    .thead-pax-rate-total {
      .number-of-person {
        border: 2px solid rgba(1,1,1,0.20);
      }
  
      .rate-per-person {
        border: 2px solid rgba(1,1,1,0.20);
      }
  
      .total-to-pay {
        border-width: 2px 0 0 0;
        border-style: solid;
        border-color: rgba(1,1,1,0.20);
      }
    }

    .tbody-pax-rate-total {
      .td-pax {
        border: 2px solid rgba(1,1,1,0.20);
      }
  
      .td-rate {
        border: 2px solid rgba(1,1,1,0.20);
      }
  
      .td-total {
        border-width: 2px 0 2px 2px;
        border-style: solid;
        border-color: rgba(1,1,1,0.20);
      }
    }
  }

  .table-inclusions {
    .thead-inclusions {
      border: 2px solid rgba(1,1,1,0.20);
    }

    .tbody-inclutions {
      border: 2px solid rgba(1,1,1,0.20);
      
      .tr-details {

        .td-inclutionsinclusion-details {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 20px 20px 20px 20px;
        }
      }
    }
  }
`;

const TableComponentMobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  ul {
    padding: 10px 0 10px 20px;

    li {
      font-size: ${text.small}
    }
  }

  .table-pax-rate-total {
    .thead-pax-rate-total {
      font-size: ${text.small};
      .number-of-person {
        border: 2px solid rgba(1,1,1,0.20);
      }
  
      .rate-per-person {
        border: 2px solid rgba(1,1,1,0.20);
      }
  
      .total-to-pay {
        border: 2px solid rgba(1,1,1,0.20);
        padding-right: 20px;
      }
    }

    .tbody-pax-rate-total {
      .td-pax {
        border: 2px solid rgba(1,1,1,0.20);
      }
  
      .td-rate {
        border: 2px solid rgba(1,1,1,0.20);
      }
  
      .td-total {
        border: 2px solid rgba(1,1,1,0.20);
      }
    }
  }


`;

export { TableComponentContainer, Table, THead, TH, TR, TD,
  InclutionTableContainer,
  RatePerPaxAndTotalContainer,
  TableComponentDesktopContainer,
  TableComponentMobileContainer
};