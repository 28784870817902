import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';
import { device, icon } from '../../../utilities/sizes.util';


export const HamburgereMenuContainer = styled.div`
    display: none;

    @media screen and ${device.smobile} {
        display: flex;
    }

    @media screen and ${device.mobile} {
        display: flex;
    }

    @media screen and ${device.tablet} {
        display: flex;
    }

`;

export const HamburgerIcon = styled(FaBars)`
    font-size: ${icon.xlarge};
    color: black;

    &:hover {
        color: gainsboro;
    }
`;

