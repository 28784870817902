import styled from 'styled-components';
import { text, icon } from '../../utilities/sizes.util';
import { BsClock } from "react-icons/bs";

const borderColor = 'grey';
const subColor = 'grey';
const mainColor = 'black';

export const PackagesAndTourDropdownContainer = styled.div`
    display: flex;
    z-index: 996;
    margin-bottom: 20px;

    &.tablet, &.desktop, &.laptop {
        background-color: white;
        border-radius: 8px;
        border-right: 2px solid #DDD;
        border-left: 2px solid #DDD;
        border-bottom: 2px solid #DDD;
        position: absolute;
    }
`;

export const OptGroup = styled.div`
    display: flex;
    flex-direction: column;

    optgroup {
        display: flex;
        flex-direction: column;
        font-size: ${text.small};
        padding: 10px 40px;
    }

    option {
        padding: 10px 0 0 20px;
        cursor: pointer;
        font-size: ${text.small};
        
        &:hover {
            background-color: #f0f0f0
        }
    }
`

export const TimePickerDropdownComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
    gap: 20px;

    input {
        background: none;
        background-color: white;
        color: ${mainColor};
        font-size: ${text.medium};
        padding: 10px 10px 10px 10px;
        display: block;
        width: 100%;
        border: 2px solid ${subColor};
        border-radius: 8px;
      
        &:focus {
          outline: none;
        }
    }

    .time-label {
        display: block;
    }

    .none {
        display: none
    }

    label {
        color: ${mainColor};
        font-size: ${text.xsmall};
        font-weight: normal;
        pointer-events: none;
        transition: 300ms ease all;
        padding: 0 0 5px 10px;
    }

    .dropdown {
        margin-top: 5px;
        padding: 3px 0;
        border: 2.2px solid darkgray;
        border-radius: 8px;
        max-width: 240px;
        width: 100%;
    }

    .time {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 2px solid ${borderColor};
        border-radius: 8px;
        padding: 10px 10px;
        width: auto;
        height: auto;
        cursor: pointer;
    }

    .time-value {

        .time-of-arrival {
            color: ${subColor};
        }
    }

    .option {
        cursor: pointer;
        font-size: ${text.medium};
        padding: 2px 15px 2px 15px;

        &:hover {
            background-color: #f0f0f0;
            padding: 5px 15px 5px 15px;
        }
    }

    .hour {
        max-height: 330px;
        overflow: auto;
    }

    .minutes {
        max-height: 330px;
        overflow: auto;
    }

    .alert {
        color: red;
        font-family: cursive;
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
      }

    .dropdown {
        display: flex;
        flex-direction: row;
        background-color: white;
    }

    .nav {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 10px;

        .hour-am-pm {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .hour-24-12 {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
            .am-pm {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .custom-button.am, .custom-button.pm {
                    border: 2px solid  green;
                    color: black;
                }

            }
        }

        .cancel-set-exit {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .custom-button.selected {
            border: 2px solid  green;
            color: black;
            }

        .custom-button.opacity {
            border: 2px solid black;
            color: black;
            }

        button {
    
            &:hover {
                border: 2.2px solid darkgray;
                color: darkgray;
            }
        }

    }
`;

export const TimeIconContainer = styled(BsClock)`
  font-size: ${icon.small};
`;

/*
        input[type='number'] {
            max-width: 40px;
            width: 100%;
            text-align: center;
        }

        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
        }
*/