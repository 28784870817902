import React, { createContext, useState } from 'react';

export const IsContext = createContext({
    isMenuOpen: false,
    setIsMenuOpen: () => {},
    isHover: false,
    setIsHover: () => {},
    isActive: false,
    setIsActive: () => {},
    isSelected: false,
    setIsSelected: () => {},
    isDetails: false,
    setIsDetails: () => {},
    isBookingForm: false,
    setIsBookingForm: () => {},
    isCheckoutForm: false,
    setIsCheckoutForm: () => {},
    isPhotos: false,
    setIsPhotos: () => {},
    isSelectedMinute: '--',
    setIsSelectedMinute: () => {},
    isSelectedHour: '--',
    setIsSelectedHour: () => {},
    isAmPm: false,
    setIsAmPm: () => {}
  });
  
  export const IsProvider = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isHover, setIsHover] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isDetails, setIsDetails] = useState(false);
    const [isBookingForm, setIsBookingForm] = useState(false);
    const [isCheckoutForm, setIsCheckoutForm] = useState(false);
    const [isPhotos, setIsPhotos] = useState(false);
    const [isSelectedMinute, setIsSelectedMinute] = useState('--');
    const [isSelectedHour, setIsSelectedHour] = useState('--');
    const [isAmPm, setIsAmPm] = useState(null)

    const value = {
        isMenuOpen,
        setIsMenuOpen,
        isHover,
        setIsHover,
        isActive,
        setIsActive,
        isSelected,
        setIsSelected,
        isDetails,
        setIsDetails,
        isBookingForm,
        setIsBookingForm,
        isCheckoutForm,
        setIsCheckoutForm,
        isPhotos,
        setIsPhotos,
        isSelectedHour,
        setIsSelectedHour,
        isSelectedMinute,
        setIsSelectedMinute,
        isAmPm,
        setIsAmPm
      };
    
      return (
      <IsContext.Provider value={value}>{children}</IsContext.Provider>
      )
    };