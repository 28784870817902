import React from "react";
import { FaCcMastercard, FaCcVisa, FaCreditCard, FaGooglePay } from "react-icons/fa";
import { CardContainer, AcceptedPaymentContiner } from "./accepted-payment.styles";

export const AcceptedPaymentComponent = () => {
    return (
        <AcceptedPaymentContiner>
            <CardContainer className='master card'>
                <FaCcMastercard />
            </CardContainer>
            <CardContainer className='visa card'>
                <FaCcVisa />
            </CardContainer>
            <CardContainer className='credit card'>
                <FaCreditCard />
            </CardContainer>
        </AcceptedPaymentContiner>
    )
};

            /*<CardContainer className='debit'>
                <FaGooglePay />
            </CardContainer>*/