import styled from 'styled-components';

export const HeaderContainer = styled.section`
    display: flex;
    position: fixed;
    flex-direction: column;
    width: 100%;
    z-index: 999;
    background-color: deepskyblue;
    border-bottom: 2px solid rgba(1,1,1,0.20);
    padding: 0 20px;

    &.mobile, &.smobile {
        height: 60px;
    }
  
    
`;

export const UpperContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
`;

export const DropdownContainer = styled.div`
    margin-top: 60px;
    display: flex;
    flex: 1;
    position: fixed;
    width: 100%;
    height: auto;
    z-index: 998;
    justify-content: center;
    background-color: deepskyblue;
    //background-color: rgb(0, 210, 255);
    border-bottom: 2px solid rgba(1,1,1,0.20);
  `;