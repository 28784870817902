import React from "react";
import { SwitchToggleComponentContainer } from "./toggle.component.styles";
  
export const SwitchToggleComponent = ({label, value, onChange }) => {

    return (
        <SwitchToggleComponentContainer className="container">
          <div className="beside-label">
            {label}
          </div>
          <div className="toggle-switch">
            <input type="checkbox" className="checkbox" onChange={onChange}
                   name={label} id={label} value={value}/>
            <label className="label" htmlFor={label}>
              <span className="inner" />
              <span className="switch" />
            </label>
          </div>
        </SwitchToggleComponentContainer>
      );   
}