import React, {useContext} from 'react';
import { AboutusContainer, OurStory } from './aboutus.component.styles';
import { ResponsiveScreenContext } from '../../contexts/screen.context';


export const AboutusComponent = () => {
    const {currentScreen} = useContext(ResponsiveScreenContext);
    
    return(
        <AboutusContainer>
            <OurStory className={currentScreen === 'mobile' || currentScreen === 'smobile' ? 'column-ourstory' : 'row-ourstory'}>
                <h2>Our Story</h2>
                <p>
                    Booking Gateway is a <span> DOT (Department of Tourism) </span> accredited and 
                    <span> LTFRB (Land Transportation Regulatory and Franchising Board) </span> 
                    registered travel and tour agency.
                </p>
            </OurStory>
        </AboutusContainer>
    )
}