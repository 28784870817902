import styled from "styled-components";
import { icon, text } from "../../utilities/sizes.util";

export const StyledLI = styled.li`
    text-align: center;
    letter-spacing: 0.40px;
    list-style: none;
    text-decoration: none;

    .packages-and-tour {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 24px 0;
        
        .icon {
            font-size: ${icon.medium};
            text-align: center;
        }
    }
`;