import React from 'react';
import { StyledUL } from './list-menu.header.styles';
import { ListComponent } from '../../list/list.component';

export const ListMenuComponent = ({ layout }) => {

  const handleClick = () => {
    window.scrollTo(0, 0);
  }
  return (
    
    <StyledUL layout={layout}>
      <ListComponent linkTo="/packages" text="Packages & Tours" onClick={handleClick}/>
      <ListComponent linkTo='/aboutus' text="About us" onClick={handleClick}/>
      <ListComponent linkTo="/contacts" text="Contacts" onClick={handleClick}/>
      <ListComponent linkTo="/" text="Home" onClick={handleClick}/>
    </StyledUL>
  );
};