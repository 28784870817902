import styled from 'styled-components';
import { LiaTimesSolid } from "react-icons/lia";
import { icon } from '../../../utilities/sizes.util'; 


export const CloseIconContainer = styled.div`
    display: flex;
`;

export const CloseIcon = styled(LiaTimesSolid)`
    font-size: ${icon.large};
    color: gainsboro;
    transition: transform 1s ease-in-out;
    transform: scale(1.2);
    //transition: transform 0.3s ease;
    //transform: rotate(180deg);

    &:hover {
        color: black;
    }
`;

