import styled from 'styled-components';
import { text } from '../../utilities/sizes.util';

export const TourContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

export const TourListNavComponentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;

`;

export const TourBigScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  max-width: 100%;
  margin: 70px 0 0 0;

  
  .card-and-tourname-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 20px;

    .card {
      flex: 1.8;
      justify-content: center;
    }

    .tour-name-details {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-start;
      gap: 10px;
      padding: 20px;

      h3 {
        white-space: nowrap;
      }

      p {
        max-height: 280px;
        overflow: auto;
        text-align: center;
        padding: 0 20px;
      }
    }
  }

  .table-and-booking-form-section {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    

    .table-and-booking-form {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      gap: 20px;

      .table {
        display: flex;
        flex: 2;
      }

      .booking-form {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-item: center;
        justify-content: flex-start;
        border: 2px solid rgba(1,1,1,0.20);
        padding: 20px 20px;

        p {
          text-align: center;
          align-item: center;
          align-content: center;
          justify-content: center;
        }
      }
    }
  }
`;

export const TourSmallScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  max-width: 100%;

  
  .card-and-tourname-section {
    margin: 180px 0 0 0;

    .card {
      display: flex;
      flex-direction: column;
      gap: 40px;
      padding: 10px 0 30px 0;
    }

    .details-and-title {
      padding: 20px 20px;
      p {
        height: auto;
        text-align: center;
        padding: 20px 0; 
      }
    }
  }

  .table-and-booking-form-section {
    display: flex;
    flex-direction: column;

    .table-and-booking-form {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      gap: 20px;

      .table {
        margin: 0 20px;
        display: flex;
        flex: 2;
      }

      .booking-form {
        margin: 180px 0 0 0;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-item: center;
        justify-content: flex-start;
        padding: 0 20px;

        p {
          text-align: center;
          align-item: center;
          align-content: center;
          justify-content: center;
        }
      }
    }
  }

`;

export const DropdownSelectContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 0 20px 0;
    padding: 0;
`;

export const SelectStyles = {
    control: (provided, state) => ({
      // Styles for the control wrapper
      ...provided,
      cursor: 'pointer',
      border: state.isFocused ? '2px solid #3498db' : '1px solid #ced4da',
      borderRadius: '4px',
      boxShadow: state.isFocused ? '0 0 0 2px #3498db50' : 'none',
      '&:hover': {
        border: '2px solid #3498db',
      },
    }),

    option: (provided, state) => ({
      // Styles for each option
      ...provided,
      background: state.isFocused ? '#f0f0f0' : 'white',
      color: '#333',
      cursor: 'pointer',
      padding: '8px 12px',
      '&:hover': {
        background: '#f0f0f0',
      },
    }),
    menu: (provided) => ({
      // Styles for the dropdown menu
      ...provided,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      zIndex: 2, // Ensure the dropdown appears above other elements
    }),
  };


/*
export const TourContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

    ul {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        white-space: nowrap;
        gap: 10px;
        padding: 0;
        margin: 115px 0 0 0;
    }

    li {
        border: 2px solid #ccc;
        padding: 5px;
        text-align: center;
    }

    .tablet {
        margin: 80px 0 0 0;
        grid-template-columns: repeat(3, 1fr);
    }

    .mobile, .smobile {
        margin: 80px 0 0 0;
        grid-template-columns: repeat(1, 1fr);
    }
 */