import styled from 'styled-components';
import { text } from '../../utilities/sizes.util';


export const DesktopFooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 50px 20px;
    background-color: #3E3E3E;
    margin: 30px 0 0 0;
    width: 100%;

    .logo {
        display: flex;
        justify-content: center;
        background-color: white;
        border-radius: 8px;
        border: 2px solid #DDD;
        padding: 10px 20px;
        height: 220px;
        width: auto;
    }

    .accepted-payments {
        display: flex;
        flex-direction: column;
        justify-content:  center;
        gap: 20px;
        background-color: white;
        border-radius: 8px;
        border: 2px solid #DDD;
        height: 220px;
        width: auto;
        max-width: 100%;
        padding: 20px 30px;
        
        h2 {
            text-align: center;
        }
    }

    .info {
        display: flex;
        flex-direction: row;
        aling-items: center;
        gap: 40px;
    }

    .about-and-social {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }



    ul {
        display: inline-block;
        text-decoration: none;
        list-style: none;

        h3 {
            color: white;
            padding: 0 0 10px 0;
            margin: 0;
    
        }

        li {
            color: white;
            &:hover {
                color: deepskyblue;
            }
        }

        li a {
            color: white;
            font-size: ${text.medium};
            

            &:hover {
                color: deepskyblue;
            }
        }
    }

`;


export const MobileFooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
    background-color: #3E3E3E;
    width: 100%;
    margin-top: 30px;

    .logo {
        display: flex;
        justify-content: center;
        background-color: white;
        border-radius: 8px;
        border: 2px solid #DDD;
        padding: 20px 0;
        height: auto;
        min-width: 224px;
        width: 100%;
    }

    .accepted-payments {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        background-color: white;
        border-radius: 8px;
        border: 2px solid #DDD;
        padding: 20px 30px;
        height: auto;
        min-width: 224px;
        width: 100%;
        
        h2 {
            text-align: center;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        aling-items: center;

    }



    ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        list-style: none;
        padding: 20px 0;
        margin: 0;

        h3 {
            text-align: center;
            color: white;
            padding: 0 0 10px 0;
            margin: 0;
        }

        li {
            text-align: center;
            color: white;
            &:hover {
                color: deepskyblue;
            }
        }

        li a {
            color: white;
            font-size: ${text.small};
            
            &:hover {
                color: deepskyblue;
            }
        }
    }

`;

export const FooterContainer = styled.footer`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .copyright-text-mobile {
        display: flex;
        flex-direction: column;
        color: white;
        background-color: #2B2B2B;
        height: 70px;
        align-items: center;
        justify-content: center;
        font-size: ${text.small};
        letter-spacing: 0.4px;
        width: 100%;
    }

    .copyright-text-desktop {
        color: white;
        background-color: #2B2B2B;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${text.medium};
        letter-spacing: 0.4px;
        line-height: 22px;
        width: 100%;
    }
`;