import React, {useContext} from 'react';
import { AboutusContainer, Personals, OurStory, Contacts } from './aboutus.route.styles';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import { FooterComponent } from '../../components/footer/footer.component';
import { SeparatorComponent } from '../../components/separator/broken-line.separator.component';
import { AboutusComponent } from '../../components/aboutus/aboutus.component';
import { PersonalComponent } from '../../components/personals/personals.component';


export const Aboutus = () => {
    const {currentScreen} = useContext(ResponsiveScreenContext);
    
    return (
        <AboutusContainer className={currentScreen}>
            <SeparatorComponent text={<h1>About Us</h1>} />
            <AboutusComponent />
            <PersonalComponent />
            <FooterComponent /> 
        </AboutusContainer>
    )
}
