import React, { useContext } from 'react';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import { FooterLogoContainer, LogoText } from './logo.styles';
import LogoImage from '../../assets/logo152.png'

export const FooterLogoComponent = () => {
  const {currentScreen} = useContext(ResponsiveScreenContext);

  return (
      <FooterLogoContainer className={currentScreen}>
            <img src={LogoImage} alt='Logo'/>
            <LogoText><strong>Booking Gateway</strong></LogoText>
      </FooterLogoContainer>
  );
}