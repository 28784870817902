import styled from 'styled-components';
import { device, text } from '../../utilities/sizes.util';

export const BaseButton = styled.button`
  min-width: 130px;
  width: auto;
  height: 40px;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding: 0 30px;
  font-size: ${text.button};
  background-color: deepskyblue;
  color: white;
  text-transform: uppercase;
  font-weight: bolder;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;




  &:hover {
    background-color: black;
    color: black;
    border: 1px solid black;
    color: white;
  }
`;

export const GoogleSignInButton = styled(BaseButton)`
  background-color: #4285f4;
  color: white;

  &:hover {
    background-color: #357ae8;
    border: none;
  }
`;

export const InvertedButton = styled(BaseButton)`
  background-color: black;
  color: white;
  border: 1px solid black;

  &:hover {
    background-color: deepskyblue;
    /*background-color: rgba(0, 191, 255, 0.7); DeepSkyBlue with 70% opacity */
    color: white;
    border: none;
  }
`;

export const BorderedButton = styled.button`;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding: 8px 5px;
  font-size: ${text.button};
  border: 2px solid black;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  background-color: white;
`;