import React from 'react';
import Carousel from 'react-grid-carousel';
import { SliderContainer } from './carousel-slider.styles';



export const CarouselSliderComponent = ({ data }) => {
  const photosLength = data.photos.length;
  let rows = 1;
  let cols = 1;

  if (photosLength === 2) {
    cols = 2;
  } else if (photosLength === 3) {
    cols = 3;
  } else if (photosLength === 4 ||  photosLength === 5 ) {
    cols = 3;
  } else if (photosLength === 6 ) {
    rows = 2;
    cols = 3;
  } else if (photosLength === 7 ) {
    rows = 2;
    cols = 3;
  } else if (photosLength === 8 ) {
    rows = 2;
    cols = 4;
  } else if (photosLength >= 9) {
    return (
      <SliderContainer className="slider-container">
        <Carousel  rows={2} cols={4} gap='5px' loop>
          {data.photos.map((photo, index) => (
            <Carousel.Item key={index}>
              <img src={photo.source} alt={`${photo.name}`} />
            </Carousel.Item>
          ))}
        </Carousel>
      </SliderContainer>
    );
  }

  return (
    <SliderContainer className="slider-container">
      <Carousel  rows={rows} cols={cols} gap='5px'>
        {data.photos.map((photo, index) => (
          <Carousel.Item key={index}>
            <img src={photo.source} alt={`${photo.name}`} />
          </Carousel.Item>
        ))}
      </Carousel>
    </SliderContainer>
  );
};

/*
containerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
*/