import React, {useContext} from 'react';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import Carousel from 'react-grid-carousel';
import { CarouselSliderContainer } from './desktop.card-slider.styes';

export const DesktopCardSliderComponent = ({ children}) => {
  const {currentScreen} = useContext(ResponsiveScreenContext);

  let rows = 1;
  let cols = 4;

    if (currentScreen === 'tablet') {
      cols = 3
    } 

  return (
    <CarouselSliderContainer>
      <Carousel
        cols={cols}
        rows={rows}
        gap={'18px'}
        loop
      >
        { children }
    </Carousel>
    </CarouselSliderContainer>
  )
};
