import React, { useContext } from 'react';
import { PackagesAndTourDropdownContainer, OptGroup } from './dropdown.styles';
import { PackageContext } from '../../contexts/packages.data.context';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import { RoutNameContext } from '../../contexts/route-name.context';
import { IsContext } from '../../contexts/is.context';
import { toURLFriendlyString } from '../../utilities/striper.util';
import { useNavigate } from 'react-router-dom';


export const PackagesAndTourDropdownComponent = () => {
  const { pkgs } = useContext(PackageContext);
  const { currentScreen } = useContext(ResponsiveScreenContext);
  const { setRouteName} = useContext(RoutNameContext);
  const { setIsHover } = useContext(IsContext);
  const  navigate  = useNavigate();

  const onClickNavigation = (name) => {
      setRouteName(name)
      const urlFriendlyString = toURLFriendlyString(name);
      window.scrollTo(0, 0);
      navigate(`/package/${urlFriendlyString}`);
      setIsHover(false);
    };

  return (
      <PackagesAndTourDropdownContainer className={currentScreen}>
          <OptGroup>
            {pkgs.map((packageItem) => (
              <optgroup label={packageItem.packageName} key={packageItem.packageName}>
                {packageItem.tours.map((tour, index) => (
                  <option onClick={() => onClickNavigation(tour.tourName)} key={index} value={tour.tourName}>
                    {tour.tourName}
                  </option>
                ))}
              </optgroup>
            ))}
          </OptGroup>
    </PackagesAndTourDropdownContainer>
  );
}
