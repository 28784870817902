import styled from "styled-components";
import { device } from '../../utilities/sizes.util';
import { SeparatorComponentContainer } from "../../components/separator/broken-line.separator.styles";

export const HomeContainer = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const HeroContainer = styled.div`
    display: flex;
    position: relative;
    margin-top: 80px;

    img {
      width: 100%;
      height: auto;
    }

    @media screen and ${device.tablet} {
      margin-top: 59px;
    }

    @media screen and ${device.mobile} {
      margin-top: 59px;
    }

    @media screen and ${device.smobile} {
      margin-top: 59px;
  
    }

    .content-container {
      position: absolute;
      left: 50px;
      top: -50px;;
      width: 100%;
      height: 100%;
      z-index: 0;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        h1 {
          color: white;
          font-size: calc(10px + 4vmax);
          margin-bottom: 10px;
          font-weight: normal;
        }

      p {
        font-size: calc(5px + 2vmax);
        color: white;
        margin: 0;
      }

      button {
        margin-top: 25px;
        height: 50px;
        width: 220px;
      }
    }
  }

`;

export const SliderSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-contents: center;
    justify-content: center;
    width: 100%;

    h1 {
      position: relative;
      text-align: center;
      /*border-bottom: 4px solid deepskyblue;*/
    }
`;

export const HeaderContainer = styled(SeparatorComponentContainer)`
    display: flex;
    flex-direction: center;
    padding: 40px 0 20px 0;
`



