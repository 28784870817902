import React, {useContext } from "react";
import { PackageContext } from "../../contexts/packages.data.context";
import { ResponsiveScreenContext } from "../../contexts/screen.context";
import { HeroContainer, SliderSectionContainer, HomeContainer, HeaderContainer } from './home.styles';
import  Image from '../../assets/hero_photos/Hero_Large_Desktop.jpg';
import { SeparatorComponent } from "../../components/separator/broken-line.separator.component";
import Carousel from 'react-grid-carousel';
import { DesktopCardSliderComponent } from "../../components/card-slider/desktop.card-slider";
import { CardComponent } from "../../components/card/card.component";
import { MobileSliderComponent } from "../../components/card-slider/mobile-slider.component";
import { FooterComponent } from "../../components/footer/footer.component";
import { Button, BUTTON_TYPE_CLASSES } from "../../components/button/button.component";
import { Link } from "react-router-dom";

export const Home = () => {
    const { tours, pkgs } = useContext(PackageContext);
    const {currentScreen} = useContext(ResponsiveScreenContext);

    return (
        <HomeContainer className={currentScreen}>
            <HeroContainer>
                <picture>
                    <img src={Image} alt='Hero'/>
                </picture>
                {(currentScreen === 'desktop' || currentScreen === 'laptop' || currentScreen === 'tablet' )
                            &&
                    <div className='content-container'>
                        <div className='content'>
                            <h1>Travel. Discover. Explore.</h1>
                            {(currentScreen === 'desktop' || currentScreen === 'laptop') && 
                            <>
                                <p>Where dreams meet reality -</p>
                                <p>Your perfect vacation starts here</p>
                            </>
                            }
                            <Link to={'/packages'}>
                                {(currentScreen === 'desktop' || currentScreen === 'laptop') && 
                                <Button buttonType={BUTTON_TYPE_CLASSES.inverted}>
                                     Lets Go!
                                </ Button>
                                }

                            </Link>
                            
                        </div>
                    </div>
                }
            </HeroContainer>
      
                <SliderSectionContainer >
                <HeaderContainer>
                  <SeparatorComponent text={<h1>Tour Packages</h1>} />
                </HeaderContainer>
                {currentScreen === 'mobile' || currentScreen === 'smobile' ? (
                    <MobileSliderComponent data={tours} >
                        {tours.map((tour, index) => (
                            <CardComponent
                            key={index}
                            header={tour.tourName}
                            image={tour.image.source}
                            imageName={tour.image.name}
                            details={`From ${tour.prices[10].ratePerPerson} PHP rate/person for a group of 12`}
                            tourName={tour.tourName}
                            />
                        ))}
                    </MobileSliderComponent>
            ):(
                <DesktopCardSliderComponent >
                    {tours.map((tour, index) => (
                        <Carousel.Item key={index}>
                            <CardComponent
                                key={index}
                                header={tour.tourName}
                                image={tour.image.source}
                                imageName={tour.image.name}
                                details={`From ${tour.prices[10].ratePerPerson} PHP rate/person for a group of 12`}
                                tourName={tour.tourName}
                            />
                        </Carousel.Item>
  
                    ))}
                </DesktopCardSliderComponent>
            )}
            </SliderSectionContainer>

            <FooterComponent />
        </HomeContainer>
    )
}
