import styled from 'styled-components';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';
import {icon } from '../../utilities/sizes.util';

export const SliderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
        padding: 10px 35px;
    }

    .swiper {
        align-items: center;
        overflow: auto;
        max-width: 340px;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0
    }

    &.mobile {
        .swiper {
            height: 100%;
            max-width: 520px;
            width: 100%;
        }
    }


`;

export const NextButton = styled(FaChevronCircleLeft)`
    font-size: ${icon.large};
    color: deepskyblue;
    cursor: pointer;
    z-index: 997;
    position: absolute;
    left: 15px;
`;

export const PrevButton = styled(FaChevronCircleRight)`
    font-size: ${icon.large};
    color: deepskyblue;
    cursor: pointer;
    z-index: 997;
    position: absolute;
    right: 15px;
`;