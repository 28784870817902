import React, { Fragment } from 'react';
import { useContext } from "react";
import { IsContext } from '../../contexts/is.context';
import { ResponsiveScreenContext } from '../../contexts/screen.context';
import { Outlet, Link } from 'react-router-dom';
import { HeaderContainer,
  UpperContainer,
  LowerContainer,
  DropdownContainer } from './header.styles';
import { HamburgerIconComponent } from './hamburger/icon.hamburger.component';
import { CloseIconComponent } from './hamburger/icon.close.component';
import { HeaderLogoComponent } from '../logo/header.logo.component';
import { ListHeaderComponent } from './nav-list-header/nav-list-header.header.component';
import { ListMenuComponent } from './nav-list-menu/list-menu.header.component';

export const Header = () => {

  const { isMenuOpen, setIsMenuOpen } = useContext(IsContext);
  const { currentScreen } = useContext(ResponsiveScreenContext);
  const { isHover } = useContext(IsContext);

  const toggleIcons = () => {
    setIsMenuOpen(false);
    window.scrollTo(0, 0)
  }


  return (
    <Fragment>
      <HeaderContainer className={currentScreen}>
        <UpperContainer className='upper-container'>
          <Link className='logo-link' to='/' onClick={toggleIcons}>
            <HeaderLogoComponent className='logo' />
          </Link>
          {currentScreen === 'mobile' || currentScreen === 'smobile' ? (
            <>
            {isMenuOpen ? (
              <CloseIconComponent />
            ):(
              <HamburgerIconComponent />
            )}
            </>

          ):(
            <ListHeaderComponent/>
          )}
        </UpperContainer>
      </HeaderContainer>
      {isMenuOpen  && 
      <DropdownContainer>
        <ListMenuComponent />
      </DropdownContainer>
      }

    <Outlet />
    </Fragment>
  );
};

