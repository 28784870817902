import styled from 'styled-components';


export const AboutusContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

`;

export const OurStory = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*border-radius: 8px;
    border: 2px solid #DDD;
    padding: 20px 60px;*/
    max-width: 824px;
    width: 100%;
    height: auto;

    h2 {
        text-align: center;
        margin: 0;
        padding: 0;
    }

    p {
        text-align: center;
        span {
            text-decoration: underline;
            font-weight: bold;
        }
    }
`;
